import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const UseSuccess = (message = 'Data berhasil diinput.') => {
  MySwal.fire({
    icon: 'success',
    title: 'Berhasil',
    text: message,
    showConfirmButton: true,
  });
};
