import { useAtom } from 'jotai';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiClient from '../../api/axios';
import { jabatanAtom } from '../../store/jotai';

const ModalKades = (props) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState({});

  const jabatan = useAtom(jabatanAtom);
  const kotaList = queryClient.getQueryData('kota');
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const { isFetching } = useQuery(
    ['user', props.data],
    () => {
      return apiClient.get(`/admin/users/id/${props.data}`);
    },
    {
      enabled: props.data !== undefined,
      retry: false,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        setUser((prev) => data.data.data);
        getKecamatan.mutate(data.data.data.kota_id);
        getKelurahan.mutate(data.data.data.kecamatan_id);
      },
    }
  );

  const getKecamatan = useMutation(
    ['getKecamatan', user.kota_id],
    (kotaId) => {
      return apiClient.get(`/show/kecamatan/${kotaId}`);
    },
    {
      enabled: !!user.kota_id,
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan', user.kecamatan_id],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!user.kecamatan_id,
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  return (
    <Modal
      key={props.data}
      size="lg"
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Detail User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetching ? (
          <div className="d-flex justify-content-center my-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form className="mt-2">
            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Nama</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  // onChange={(e) => setData({ ...data, name: e.target.value })}
                  defaultValue={user.name}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">NIK</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={user.nik}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Email</label>
              </div>
              <div className="col-7">
                <input
                  type="email"
                  className="form-control"
                  defaultValue={user.email}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Nomor HP</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={user.nomor_hp}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Jabatan</label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    // console.log(e.target.value);
                  }}
                  defaultValue={user.jabatan}
                >
                  <option hidden value={''}>
                    Pilih Jabatan
                  </option>
                  {jabatan[0].map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Kabupaten / Kota
                </label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kabupaten / Kota"
                  onChange={(e) =>
                    setUser({ ...user, kota_id: e.target.value })
                  }
                  defaultValue={user.kota_id}
                >
                  <option hidden value={''}>
                    Pilih Kota
                  </option>
                  {kotaList?.data.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Kecamatan</label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  onChange={(e) => {
                    setUser({ ...user, kecamatan_id: e.target.value });
                  }}
                  value={user.kecamatan_id}
                >
                  <option hidden value={''}>
                    Pilih Kecamatan
                  </option>
                  {kecamatanList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Kelurahan / Desa
                </label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kelurahan / Desa"
                  onChange={(e) =>
                    setUser({ ...user, kelurahan_id: e.target.value })
                  }
                  value={user.kelurahan_id}
                >
                  <option hidden value={''}>
                    Pilih Kelurahan / Desa
                  </option>
                  {kelurahanList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            {/* <div className="row g-5 mb-3">
              <div className="col-7 offset-md-3">
                <button type="submit" className="btn btn-primary">
                  Simpan
                </button>
              </div>
            </div> */}
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalKades;
