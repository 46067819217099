const DokumenRutena = ({ data }) => {
  return (
    <>
      <div className="row dokumen">
        <div className="col-6 mb-3">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              data.dokumen_permohonan
                ? window.open(data.dokumen_permohonan_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>Surat Permohonan Bupati / Kota</span>
                {!data.dokumen_permohonan && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>

        <div className="col-6">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              data.dokumen_sk
                ? window.open(data.dokumen_sk_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>Dokumen SK</span>
                {!data.dokumen_permohonan && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default DokumenRutena;
