import { useAtom } from 'jotai';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiClient from '../../api/axios';
import { UseError } from '../../hooks/useError';
import { kotaAtom } from '../../store/jotai';

const ModalAdminKota = (props) => {
  const queryClient = useQueryClient();
  const [data, setData] = useState({});

  const [kotaList, setKotaList] = useAtom(kotaAtom);

  const getKota = useQuery(
    'kota',
    () => {
      return apiClient.get('/show/kota');
    },
    {
      enabled: !kotaList,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKotaList(data.data);
      },
    }
  );

  const addAdminKota = useMutation(
    'addAdminKota',
    (e) => {
      e.preventDefault();
      return apiClient.post('/admin/add-admin', data);
    },
    {
      onSuccess: (data) => {
        props.setShowModal(false);
        setData({});
        queryClient.invalidateQueries('adminKota');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <Modal
      key={props.data}
      size="lg"
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Tambah Admin Kabupaten / Kota
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-2" onSubmit={addAdminKota.mutate}>
          <div className="row g-5 mb-3">
            <div className="col-3 d-flex align-items-end flex-column">
              <label className="col-form-label fw-semibold">Nama</label>
            </div>
            <div className="col-7">
              <input
                type="text"
                className="form-control"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
          </div>

          <div className="row g-5 mb-3">
            <div className="col-3 d-flex align-items-end flex-column">
              <label className="col-form-label fw-semibold">Email</label>
            </div>
            <div className="col-7">
              <input
                type="email"
                className="form-control"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </div>
          </div>

          <div className="row g-5 mb-3">
            <div className="col-3 d-flex align-items-end flex-column">
              <label className="col-form-label fw-semibold">Nomor HP</label>
            </div>
            <div className="col-7">
              <input
                type="text"
                className="form-control"
                onChange={(e) => setData({ ...data, nomor_hp: e.target.value })}
              />
            </div>
          </div>

          <div className="row g-5 mb-3">
            <div className="col-3 d-flex align-items-end flex-column">
              <label className="col-form-label fw-semibold">
                Kabupaten / Kota
              </label>
            </div>
            <div className="col-7">
              <Form.Select
                aria-label="Pilih Kabupaten / Kota"
                onChange={(e) => setData({ ...data, kota: e.target.value })}
              >
                <option hidden value={''}>
                  Pilih Kota
                </option>
                {getKota.isFetched &&
                  kotaList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="row g-5 mb-3">
            <div className="col-3 d-flex align-items-end flex-column">
              <label className="col-form-label fw-semibold">Password</label>
            </div>
            <div className="col-7">
              <input
                type="password"
                className="form-control"
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </div>
          </div>

          <div className="row g-5 mb-3">
            <div className="col-7 offset-md-3">
              <button type="submit" className="btn btn-primary">
                Simpan
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAdminKota;
