import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../api/axios';
import { UseError } from '../../hooks/useError';
import { UseSuccess } from '../../hooks/useSuccess';

const ButtonAjukanKembali = ({ id, kategori }) => {
  const navigate = useNavigate();
  const ajukanKembali = useMutation(
    'ajukanKembali',
    () => {
      return apiClient.post(`/${kategori}/pengajuan/ajukan-kembali`, {
        pengajuan_id: id,
      });
    },
    {
      onSuccess: (data) => {
        UseSuccess('Data berhasil diajukan kembali.');
        navigate(`/${kategori}/pengajuan`);
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <button
        type="button"
        className={`btn btn-warning text-white px-5 py-2 ${
          ajukanKembali.isLoading && 'disabled'
        } `}
        onClick={ajukanKembali.mutate}
      >
        <span className="fs-5">Ajukan Kembali</span>
      </button>
      {ajukanKembali.isLoading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
};

export default ButtonAjukanKembali;
