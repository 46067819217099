import { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import apiClient from '../../api/axios';
import CsTable from '../../components/CsTable';
import TableModel from '../../components/CsTable/Model/TableModel';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const TableUserKota = () => {
  const queryClient = useQueryClient();
  const MySwal = withReactContent(Swal);

  const [tableData, setTableData] = useState([]);

  const { isFetching } = useQuery(
    'adminKota',
    () => {
      return apiClient.get('/admin/users/admin');
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        setTableData(data.data.data);
      },
    }
  );

  const tableModel = new TableModel([
    {
      Header: '#',
      accessor: 'no',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Nama',
      accessor: 'name',
    },

    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Kabupaten / Kota',
      accessor: 'kota',
    },
    {
      Header: 'Aksi',
      accessor: 'aksi',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          <button
            className="btn btn-sm btn-danger me-2"
            onClick={() => {
              MySwal.fire({
                title: 'Apakah anda yakin?',
                html: `Anda akan menghapus <span class='fw-semibold'>${row.original.name}</span>!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#d33',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                  try {
                    const res = await apiClient.post('/admin/delete-account', {
                      id: row.original.name,
                    });
                    queryClient.invalidateQueries('adminKota');
                    return res.data;
                  } catch (err) {
                    MySwal.showValidationMessage(`Request failed: ${err}`);
                  }
                },
                allowOutsideClick: () => !MySwal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  MySwal.fire('Terhapus!', 'Data berhasil dihapus.', 'success');
                }
              });
            }}
          >
            <iconify-icon icon={'fa-solid:trash-alt'}></iconify-icon>
          </button>
        </>
      ),
    },
  ]);

  tableModel.setRecords(useMemo(() => tableData, [tableData]));

  return (
    <>
      <CsTable
        columns={tableModel.columns}
        data={tableModel.data}
        loading={isFetching}
      />
    </>
  );
};

export default TableUserKota;
