import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import apiClient from '../../../api/axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { UseError } from '../../../hooks/useError';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../../store/jotai';

const MySwal = withReactContent(Swal);

const FotoRutena = (props) => {
  const queryClient = useQueryClient();
  const [authUser] = useAtom(authUserAtom);
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    pengajuan_id: props.data.id,
  });

  let unit = [];
  for (let i = 1; i <= props.data.jumlah_usulan_rumah; i++) {
    if (props.data?.foto[i] === undefined) {
      unit.push(i);
    }
  }

  const uploadFoto = useMutation(
    'uploadFotoRutena',
    (e) => {
      e.preventDefault();

      let multipePhoto = [];
      for (let i = 0; i < formData.foto.length; i++) {
        multipePhoto.push(formData.foto[i]);
      }

      return apiClient.post(
        'rutena/pengajuan/foto',
        {
          pengajuan_id: formData.pengajuan_id,
          unit: formData.unit,
          foto_depan: multipePhoto,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        setShowModal(false);
        queryClient.invalidateQueries('rutenaDetail');
        MySwal.fire('Berhasil!', 'Data berhasil diverifikasi.', 'success');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <button
        className="btn btn-secondary mb-4"
        onClick={() => setShowModal(true)}
      >
        Upload Foto
      </button>

      <Modal
        key={props.data.id}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Tambah Foto Pengajuan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2" onSubmit={uploadFoto.mutate}>
            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Foto</label>
              </div>
              <div className="col-7">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="form-control"
                  name="foto"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Unit</label>
              </div>
              <div className="col-7">
                <Form.Select
                  name="unit"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                >
                  <option hidden value="">
                    Pilih Unit
                  </option>
                  {unit.map((item, index) => (
                    <option key={index} value={item}>
                      Unit {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-7 offset-md-3">
                <button type="submit" className="btn btn-primary">
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {Object.keys(props.data.foto).map((key) => {
        return (
          <div className="row mb-3" key={key}>
            <div className="col">
              <div className="card">
                <div className="card-header">
                  Unit {key}
                  {authUser.id === props.data.user_id && (
                    <button
                      className="btn btn-danger btn-sm ms-2"
                      onClick={() => {
                        MySwal.fire({
                          title: 'Apakah anda yakin?',
                          html: `Anda akan menghapus Foto Unit ${key}!`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Delete',
                          confirmButtonColor: '#d33',
                          showLoaderOnConfirm: true,
                          preConfirm: async () => {
                            try {
                              const res = await apiClient.post(
                                'rutena/pengajuan/delete-foto-unit',
                                {
                                  pengajuan_id: props.data.id,
                                  unit: key,
                                }
                              );
                              queryClient.invalidateQueries('rutenaDetail');
                              return res.data;
                            } catch (err) {
                              MySwal.showValidationMessage(
                                `Request failed: ${err}`
                              );
                            }
                          },
                          allowOutsideClick: () => !MySwal.isLoading(),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            MySwal.fire(
                              'Terhapus!',
                              'Data berhasil dihapus.',
                              'success'
                            );
                          }
                        });
                      }}
                    >
                      Hapus
                    </button>
                  )}
                </div>

                <div className="col-12">
                  <div className="row row-cols-1 row-cols-md-3 m-1 mb-2">
                    {props.data?.foto[key].map((item) => {
                      return (
                        <div className="col g-2" key={item.id}>
                          <div className="card">
                            <img
                              src={item.foto_url}
                              alt=""
                              className="img-fluid p-2"
                              key={item.id}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FotoRutena;
