import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import apiClient from '../../api/axios';
import { UseError } from '../../hooks/useError';
import { UseSuccess } from '../../hooks/useSuccess';
import { kotaAtom } from '../../store/jotai';

const MySwal = withReactContent(Swal);

const ModalRegister = (props) => {
  const [formData, setFormData] = useState({});

  const [kotaList, setKotaList] = useAtom(kotaAtom);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const getKota = useQuery(
    'kota',
    () => {
      return apiClient.get('/show/kota');
    },
    {
      enabled: !kotaList,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKotaList(data.data);
      },
      onError: (error) => {
        MySwal.fire({
          title: 'Gagal!',
          text: 'Gagal mendapatkan data kota.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      },
    }
  );

  const getKecamatan = useMutation(
    ['getKecamatan'],
    (kotaId) => {
      return apiClient.get(`/show/kecamatan/${kotaId}`);
    },
    {
      enabled: !!formData?.kota,
      retry: true,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan'],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!formData?.kecamatan_id,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  const register = useMutation(
    'register',
    (e) => {
      e.preventDefault();
      return apiClient.post('/auth/register', formData);
    },
    {
      onSuccess: (data) => {
        props.setShowModal(false);
        setFormData({});
        UseSuccess(
          'Akun berhasil dibuat. Silahkan menunggu verifikasi akun oleh admin.'
        );
      },
      onError: (error) => {
        UseError(error);
      },
    }
  );

  return (
    <>
      <Modal
        size="lg"
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Registrasi Akun Desa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2" onSubmit={register.mutate} autoComplete="off">
            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Nama</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Email</label>
              </div>
              <div className="col-7">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Password</label>
              </div>
              <div className="col-7">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">NIK</label>
              </div>
              <div className="col-7">
                <input
                  type="number"
                  className="form-control"
                  name="nik"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Jabatan</label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Jabatan"
                  name="jabatan"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option hidden value="">
                    Pilih Jabatan
                  </option>
                  <option>Kepala Desa</option>
                  <option>Sekretaris Desa</option>
                  <option>Lurah</option>
                  <option>Sekretaris Lurah</option>
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Instansi</label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="instansi"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Alamat Instansi
                </label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="alamat_instansi"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Nomor Telepon
                </label>
              </div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="nomor_hp"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Kabupaten/Kota
                </label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kabupaten / Kota"
                  name="kota"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    getKecamatan.mutate(e.target.value);
                    setKelurahanList(RESET);
                  }}
                >
                  <option hidden value={''}>
                    Pilih Kabupaten/Kota
                  </option>
                  {getKota.isFetched &&
                    getKota.isSuccess &&
                    kotaList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Kecamatan</label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  name="kecamatan"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    getKelurahan.mutate(e.target.value);
                  }}
                >
                  <option hidden value={''}>
                    Pilih Kecamatan
                  </option>
                  {kecamatanList.length > 0 ? (
                    kecamatanList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>
                      Pilih Kabupaten/Kota Terlebih Dahulu
                    </option>
                  )}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Kelurahan/Desa
                </label>
              </div>
              <div className="col-7">
                <Form.Select
                  aria-label="Pilih Kelurahan/Desa"
                  name="kelurahan"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option hidden value={''}>
                    Pilih Kelurahan/Desa
                  </option>
                  {kelurahanList.length > 0 ? (
                    kelurahanList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>
                      Pilih Kabupaten/Kota Terlebih Dahulu
                    </option>
                  )}
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-7 offset-md-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    disabled={register.isLoading}
                  >
                    Simpan
                  </button>
                  {register.isLoading && (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRegister;
