const TitlePage = (props) => {
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="">
          <h4 className="fw-semibold text-uppercase ">
            {props.title ?? 'Title Page'}
          </h4>
          <p className="text-black-50 mb-0">
            {props.subtitle ?? 'Subtitle page'}
          </p>
        </div>
        <div className="d-flex align-items-start">{props.children}</div>
      </div>
    </>
  );
};

export default TitlePage;
