import CardDetail from '../../../components/CardDetail';
import KeteranganDetail from '../../../components/KeteranganDetail';

const LokasiPsu = ({ data }) => (
  <div className="d-grid gap-4">
    <CardDetail title={'Lokasi Pengusulan'}>
      <div className="col-6 pe-4">
        <KeteranganDetail title={'Provinsi'} keterangan={'Kalimantan Barat'} />

        <KeteranganDetail title={'Kabupaten/Kota'} keterangan={data.kota} />
        <KeteranganDetail title={'Kecamatan'} keterangan={data.kecamatan} />
        <KeteranganDetail
          title={'Desa/Kelurahan'}
          keterangan={data.kelurahan}
        />
      </div>

      <div className="col-6  ps-4">
        <KeteranganDetail title={'RT'} keterangan={data.rt} />
        <KeteranganDetail title={'RW'} keterangan={data.rw} />
      </div>
    </CardDetail>

    <CardDetail title={'Sketch'}>
      <div className="col-12">
        <div className="row row-cols-1 m-1">
          <div className="col g-2">
            <div className="card">
              <img src={data.sketch} alt="" className="img-fluid p-2" />
            </div>
          </div>
        </div>
      </div>
    </CardDetail>
  </div>
);

export default LokasiPsu;
