import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import apiClient from '../../../api/axios';
import Loading from '../../../components/Loading';
import ButtonAjukanKembali from '../../../components/Pengajuan/ButtonAjukanKembali';
import TitlePage from '../../../components/TitlePage';
import { UseError } from '../../../hooks/useError';
import { authUserAtom, pendatanganSuratAtom } from '../../../store/jotai';

const EditPengajuanRutena = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({});
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);
  const [date, setDate] = useState(null);
  const [pendatangan_surat] = useAtom(pendatanganSuratAtom);

  const rutenaDetail = useQuery(
    'rutenaDetail',
    () => {
      return apiClient.get(`/rutena/pengajuan/show/${id}`);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setFormData(data.data.data);
        setDate(new Date(data.data.data.tanggal_surat));
        getKelurahan.mutate(data.data.data.kecamatan_id);
      },
    }
  );

  const getKecamatan = useQuery(
    'getKecamatan',
    () => {
      return apiClient.get(`/show/kecamatan/${authUser.kota_id}`);
    },
    {
      enabled: !!authUser.kota_id,
      retry: true,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan'],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!formData?.kecamatan_id,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  const updateRutena = useMutation(
    'updateRutena',
    (e) => {
      e.preventDefault();
      if (formData.tanggal_surat) {
        // format date to yyyy-mm-dd
        const date = new Date(formData?.tanggal_surat);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        formData['tanggal_surat'] = formattedDate;
      }

      const form = new FormData();
      form.append('pengajuan_id', formData.id);
      form.append('nomor_surat', formData.nomor_surat);
      form.append('tanggal_surat', formData.tanggal_surat);
      form.append('pendatangan_surat', formData.pendatangan_surat);
      form.append('jenis_kegiatan', formData.jenis_kegiatan);
      form.append('penyediaan_rehabilitasi', formData.penyediaan_rehabilitasi);
      form.append('jumlah_rumah_terdampak', formData.jumlah_rumah_terdampak);
      form.append('jumlah_usulan_rumah', formData.jumlah_usulan_rumah);

      formData.dokumen_permohonan_file &&
        form.append('dokumen_permohonan', formData.dokumen_permohonan_file);
      formData.dokumen_sk_file &&
        form.append('dokumen_sk', formData.dokumen_sk_file);

      return apiClient.post('rutena/pengajuan/update', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        // mySwal on success
        MySwal.fire({
          title: 'Berhasil!',
          text: 'Pengajuan berhasil dikirim',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Edit Pengajuan Rutena'}
        subtitle={'Data pengajuan RUTENA yang telah diajukan oleh masyarakat'}
      />

      {rutenaDetail.isFetching ? (
        <Loading />
      ) : (
        <form className="mb-4" onSubmit={updateRutena.mutate}>
          <div className="d-grid gap-4 mb-4">
            <div className="row">
              <span className="fw-semibold mb-3 h5">Info Surat</span>

              <div className="col-3 mb-3">
                <label htmlFor="nomor_surat" className="form-label">
                  Nomor Surat :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="nomor_surat"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.nomor_surat}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="nama" className="form-label">
                  Tanggal Surat :
                </label>
                <ReactDatePicker
                  className="form-control p-2"
                  dateFormat={'yyyy-MM-dd'}
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setFormData({ ...formData, tanggal_surat: date });
                  }}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="pendatangan_surat" className="form-label">
                  Pendatangan Surat Usulan :
                </label>

                <Form.Select
                  aria-label="Pilih Penerima Manfaat"
                  name="pendatangan_surat"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={formData?.pendatangan_surat}
                >
                  <option hidden value={''}>
                    Pilih Pendatangan Surat
                  </option>
                  {pendatangan_surat.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="jenis_kegiatan" className="form-label">
                  Jenis Kegiatan :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="jenis_kegiatan"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.jenis_kegiatan}
                />
              </div>

              <div className="col-4 mb-3">
                <label htmlFor="penyediaan_rehabilitasi" className="form-label">
                  Penyediaan dan Rehabilitasi Rumah :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="penyediaan_rehabilitasi"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.penyediaan_rehabilitasi}
                />
              </div>
            </div>

            <div className="row">
              <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

              <div className="col-4 mb-3">
                <label htmlFor="nama" className="form-label">
                  Kecamatan :
                </label>
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  name="kecamatan_id"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    getKelurahan.mutate(e.target.value);
                  }}
                  value={formData.kecamatan_id}
                >
                  <option hidden value={''}>
                    Pilih Kecamatan
                  </option>
                  {getKecamatan.isFetched &&
                    kecamatanList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>

              <div className="col-4 mb-3">
                <label htmlFor="nama" className="form-label">
                  Kelurahan / Desa :
                </label>
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  name="kelurahan_id"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  value={formData.kelurahan_id}
                >
                  <option hidden value={''}>
                    Pilih Kelurahan / Desa
                  </option>
                  {kelurahanList.length > 0 ? (
                    kelurahanList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>Pilih Kecamatan Terlebih Dahulu</option>
                  )}
                </Form.Select>
              </div>

              <div className="col-6 mb-3">
                <label htmlFor="jumlah_rumah_terdampak" className="form-label">
                  Jumlah Rumah Terdampak :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="jumlah_rumah_terdampak"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.jumlah_rumah_terdampak}
                />
              </div>

              <div className="col-6 mb-3">
                <label htmlFor="jumlah_usulan_rumah" className="form-label">
                  Jumlah Usulan Rumah Terdampak yang Dimohonkan :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="jumlah_usulan_rumah"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.jumlah_usulan_rumah}
                />
              </div>
            </div>

            <div className="row">
              <span className="fw-semibold h5 mb-0">Upload Dokumen</span>
              <p className="fw-light" style={{ fontSize: 13 }}>
                Silahkan upload dokumen pada form ini dengan format file{' '}
                <span className="fw-semibold">PDF</span>
              </p>

              <div className="col-6 mb-3">
                <label htmlFor="dokumen_permohonan" className="form-label">
                  Surat Permohonan Bupati / Kota :
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="dokumen_permohonan_file"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
                <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                  Contoh Format Permohonan,{'  '}
                  <a
                    href={require('../../../assets/doc/contoh.pdf')}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    klik disini
                  </a>
                </p>
                <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                  Surat Permohonan ditunjukan kepada Gubernur Kalimantan Barat
                  cq. Kepala Dinas Perumahan Rakyat dan Kawasan Permukiman Prov.
                  Kalbar
                </p>
              </div>

              <div className="col-6 mb-3">
                <label htmlFor="dokumen_sk" className="form-label">
                  Dokumen SK Kebencaan Kab / Kota :
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="dokumen_sk_file"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <button
              type="submit"
              className={`btn btn-primary px-5 py-2 ${
                updateRutena.isLoading && 'disabled'
              } `}
            >
              <span className="fs-5">Submit</span>
            </button>
            {updateRutena.isLoading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {formData?.status === 'Ditolak Provinsi' && (
              <ButtonAjukanKembali id={id} kategori="rutena" />
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default EditPengajuanRutena;
