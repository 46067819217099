import { useAtom } from 'jotai';
import { useState } from 'react';
import { Fade } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';
import apiClient from '../../api/axios';
import { authUserAtom, tokenAtom } from '../../store/jotai';

const FormLogin = (props) => {
  const [token, setToken] = useAtom(tokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = useMutation(
    'login',
    (event) => {
      event.preventDefault();
      return apiClient.post('/auth/login', {
        email,
        password,
      });
    },
    {
      onSuccess: (res) => {
        setToken(res.data.token);
        setAuthUser({ ...res.data.data, roles: [res.data.data.role] });
      },
      onError: (err) => {
        const { data } = err.response;
        alert(data?.error);
      },
    }
  );

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Fade in={props.open}>
        <div
          id="formLogin"
          className={
            'd-flex flex-column px-5 animate__animated' +
            (props.open ? '  ' : ' d-none ')
          }
        >
          <h1 className="text-white fw-bold">Form Login</h1>
          <h6 className="text-white-50">
            Silahkan login menggunakan akun yang sudah dibuat sebelumnya
          </h6>
          <form className="mt-3" onSubmit={login.mutate}>
            <div className="form-floating mb-3">
              <input
                type="username"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <label htmlFor="floatingInput">Username</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <label htmlFor="floatingInput">Password</label>
            </div>
            <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <button
                  key={'login'}
                  className="btn btn-light py-3 me-3"
                  style={{ width: 200 }}
                  type="submit"
                  disabled={login.isLoading}
                >
                  Login
                </button>
                {login.isLoading && (
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
              <a
                href="/#"
                className="text-decoration-none text-white"
                onClick={(e) => {
                  e.preventDefault();
                  props.setShow(!props.open);
                }}
              >
                Kembali
              </a>
            </div>
          </form>
        </div>
      </Fade>
    </>
  );
};

export default FormLogin;
