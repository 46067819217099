import { useState } from 'react';
import TitlePage from '../../../components/TitlePage';
import DatePicker from 'react-datepicker';
import apiClient from '../../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { UseError } from '../../../hooks/useError';
import { useAtom } from 'jotai';
import {
  authUserAtom,
  kotaAtom,
  pendatanganSuratAtom,
  penerimaManfaatAtom,
} from '../../../store/jotai';
import { Form } from 'react-bootstrap';

import contoh from '../../../assets/doc/Surat Permohonan Rutiyani.docx';
import { RESET } from 'jotai/utils';

const FormPengajuan = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const MySwal = withReactContent(Swal);
  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({});
  const [date, setDate] = useState(null);

  const [penerima_manfaat] = useAtom(penerimaManfaatAtom);
  const [pendatangan_surat] = useAtom(pendatanganSuratAtom);

  const [kotaList, setKotaList] = useAtom(kotaAtom);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const getKota = useQuery(
    'getKota',
    () => {
      return apiClient.get('/show/kota');
    },
    {
      enabled: !authUser.kota_id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKotaList(data.data);
      },
    }
  );

  const getKecamatan = useQuery(
    ['getKecamatan', formData.kota || authUser.kota_id],
    () => {
      const kota = formData.kota || authUser.kota_id;
      return apiClient.get(`/show/kecamatan/${kota}`);
    },
    {
      enabled: !!authUser.kota_id || !!formData?.kota,
      retry: true,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan'],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!formData?.kecamatan_id,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  const postPengajuan = useMutation(
    'postPengajuan',
    (e) => {
      e.preventDefault();

      if (formData.tanggal_surat) {
        // format date to yyyy-mm-dd
        const date = new Date(formData.tanggal_surat);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        formData['tanggal_surat'] = formattedDate;
      }

      if (authUser.role === 'kepala_desa') {
        formData['kecamatan'] = authUser.kecamatan_id;
        formData['kelurahan'] = authUser.kelurahan_id;
      }

      return apiClient.post('rutiyani/pengajuan/kirim', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        navigate('/rutiyani/pengajuan');
        // mySwal on success
        MySwal.fire({
          title: 'Berhasil!',
          text: 'Pengajuan berhasil dikirim',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Form Pengajuan'}
        subtitle={'Data pengajuan rutiyani yang telah diajukan oleh masyarakat'}
      />

      <form className="mb-4" onSubmit={postPengajuan.mutate}>
        <div className="d-grid gap-4 mb-4">
          <div className="row">
            <span className="fw-semibold mb-3 h5">Info Surat</span>

            <div className="col-3 mb-3">
              <label htmlFor="nomor_surat" className="form-label">
                Nomor Surat :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="nomor_surat"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="tanggal_surat" className="form-label">
                Tanggal Surat :
              </label>
              <DatePicker
                className="form-control p-2"
                dateFormat={'yyyy-MM-dd'}
                selected={date}
                onChange={(date) => {
                  setDate(date);
                  setFormData({ ...formData, tanggal_surat: date });
                }}
              />
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="pendatangan_surat" className="form-label">
                Pendatangan Surat Usulan :
              </label>

              <Form.Select
                aria-label="Pilih Penerima Manfaat"
                name="pendatangan_surat"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
                value={formData?.pendatangan_surat}
              >
                <option hidden value={''}>
                  Pilih Pendatangan Surat
                </option>
                {pendatangan_surat.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="penerima_manfaat" className="form-label">
                Penerima Manfaat :
              </label>

              <Form.Select
                aria-label="Pilih Penerima Manfaat"
                name="penerima_manfaat"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              >
                <option hidden value={''}>
                  Pilih Penerima Manfaat
                </option>
                {penerima_manfaat.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className="row">
            <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

            {authUser?.roles.find((role) =>
              ['admin_provinsi'].includes(role)
            ) && (
              <div className="col-3 mb-3">
                <label htmlFor="kota" className="form-label">
                  Kabupaten/Kota :
                </label>
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  name="kota"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    queryClient.removeQueries('getKecamatan');
                    setKelurahanList(RESET);
                  }}
                >
                  <option hidden value={''}>
                    Pilih Kabupaten/Kota
                  </option>
                  {getKota.isFetched &&
                    kotaList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            )}

            {authUser?.roles.find((role) =>
              ['admin_kota', 'admin_provinsi'].includes(role)
            ) && (
              <>
                <div className="col-3 mb-3">
                  <label htmlFor="kecamatan" className="form-label">
                    Kecamatan :
                  </label>
                  <Form.Select
                    aria-label="Pilih Kecamatan"
                    name="kecamatan"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                      getKelurahan.mutate(e.target.value);
                    }}
                  >
                    <option hidden value={''}>
                      Pilih Kecamatan
                    </option>
                    {getKecamatan.isFetched ? (
                      kecamatanList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>
                        Pilih Kabupaten/Kota Terlebih Dahulu
                      </option>
                    )}
                  </Form.Select>
                </div>

                <div className="col-3 mb-3">
                  <label htmlFor="kelurahan" className="form-label">
                    Kelurahan / Desa :
                  </label>
                  <Form.Select
                    aria-label="Pilih Kecamatan"
                    name="kelurahan"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  >
                    <option hidden value={''}>
                      Pilih Kelurahan / Desa
                    </option>
                    {kelurahanList.length > 0 ? (
                      kelurahanList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>Pilih Kecamatan Terlebih Dahulu</option>
                    )}
                  </Form.Select>
                </div>
              </>
            )}

            <div className="col-3 mb-3">
              <label htmlFor="lokasi_usulan_rtlh" className="form-label">
                Lokasi Usulan RTLH :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="lokasi_usulan_rtlh"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="jumlah_unit" className="form-label">
                Jumlah Unit :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="jumlah_unit"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <span className="fw-semibold h5 mb-0">Upload Dokumen</span>
            <p className="fw-light" style={{ fontSize: 13 }}>
              Silahkan upload dokumen pada form ini dengan format file{' '}
              <span className="fw-semibold">PDF</span>
            </p>

            <div className="col-6 mb-3">
              <label htmlFor="dokumen_permohonan" className="form-label">
                Surat Permohonan dan Proposal{' '}
                {authUser.role === 'kepala_desa'
                  ? 'Kepala Desa'
                  : 'Bupati / Wali Kota'}{' '}
                :
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="form-control"
                name="dokumen_permohonan"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  });
                }}
              />

              <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                Contoh Format Permohonan,{'  '}
                <a href={contoh} target={'_blank'} rel="noreferrer">
                  klik disini
                </a>
              </p>
              <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                Surat Permohonan ditunjukan kepada Gubernur Kalimantan Barat cq.
                Kepala Dinas Perumahan Rakyat dan Kawasan Permukiman Prov.
                Kalbar
              </p>
            </div>

            {authUser.role === 'kepala_desa' && (
              <div className="col-6 mb-3">
                <label htmlFor="dokumen_sk" className="form-label">
                  Dokumen SK Kepala Desa/Lurah:
                </label>
                <input
                  type="file"
                  accept="application/pdf"
                  className="form-control"
                  name="dokumen_sk"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                />
              </div>
            )}

            {authUser?.roles.find((role) =>
              ['admin_provinsi', 'admin_kota'].includes(role)
            ) && (
              <>
                <div className="col-6 mb-3">
                  <label htmlFor="dokumen_sk_kumuh" className="form-label">
                    Dokumen SK Kumuh:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="dokumen_sk_kumuh"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <button
            type="submit"
            className={`btn btn-primary px-5 py-2 ${
              postPengajuan.isLoading && 'disabled'
            }`}
          >
            <span className="fs-5">Submit</span>
          </button>
          {/* spinner */}
          {postPengajuan.isLoading && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default FormPengajuan;
