import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import '../../assets/css/card.css';
import TitlePage from '../../components/TitlePage';
import { GetStatistik } from '../../hooks/useQueryStatistik';
import { statistikAtom } from '../../store/jotai';

const Rutiyani = () => {
  const [statistik] = useAtom(statistikAtom);

  const getStatistik = GetStatistik();

  return (
    <>
      <TitlePage
        title={'Rutiyani'}
        subtitle={
          'Berikut adalah beberapa data statistik dari pengajuan RUTIYANI'
        }
      >
        {/* <button className="btn btn-outline-secondary">2022</button> */}
      </TitlePage>

      {getStatistik.isFetched && (
        <div className="row">
          <div className="col">
            <div
              className="card rounded-4 bg-primary text-white card-primary"
              style={{ height: '8rem' }}
            >
              <div className="card-body d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="card-title fw-light mb-0">Total Pengajuan</h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.total_pengajuan_rutiyani}
                  </h1>
                </div>
                <Link
                  to="/rutiyani/pengajuan"
                  className="btn btn-sm btn-outline-light"
                >
                  Lihat Pengajuan
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card rounded-4" style={{ height: '8rem' }}>
              <div className="card-body d-flex align-items-center ">
                <div>
                  <h6 className="card-title fw-light mb-0">
                    Disetujui Provinsi
                  </h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.rutiyani?.total_pengajuan_disetujui_provinsi}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card rounded-4" style={{ height: '8rem' }}>
              <div className="card-body d-flex align-items-center ">
                <div>
                  <h6 className="card-title fw-light mb-0">Ditolak Provinsi</h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.rutiyani?.total_pengajuan_ditolak_provinsi}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Rutiyani;
