import CardDetail from '../../../components/CardDetail';
import KeteranganDetail from '../../../components/KeteranganDetail';

const LokasiJumlah = ({ data }) => (
  <div className="d-grid gap-4">
    <CardDetail title={'Lokasi Pengusulan'}>
      <div className="col-6 pe-4">
        <KeteranganDetail title={'Provinsi'} keterangan={'Kalimantan Barat'} />

        <KeteranganDetail
          title={'Kabupaten/Kota'}
          keterangan={data.users.kota}
        />
        <KeteranganDetail
          title={'Kecamatan'}
          keterangan={data.users.kecamatan}
        />
        <KeteranganDetail
          title={'Desa/Kelurahan'}
          keterangan={data.users.kelurahan}
        />
      </div>

      <div className="col-6  ps-4">
        <KeteranganDetail
          title={'Lokasi Usulan RTLH'}
          keterangan={data.lokasi_usulan_rtlh}
        />
        <KeteranganDetail title={'Jumlah Unit'} keterangan={data.jumlah_unit} />
      </div>
    </CardDetail>
  </div>
);

export default LokasiJumlah;
