import { useAtom } from 'jotai';
import { useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import apiClient from '../../../api/axios';
import CsTable from '../../../components/CsTable';
import TableModel from '../../../components/CsTable/Model/TableModel';
import { authUserAtom } from '../../../store/jotai';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const TablePengajuan = () => {
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const [authUser] = useAtom(authUserAtom);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();

  queryClient.removeQueries('psuDetail');

  const { isFetching } = useQuery(
    'psu',
    () => {
      return apiClient.get('/psu/pengajuan/show');
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setTableData(data.data.data);
      },
    }
  );

  const tableModel = new TableModel([
    {
      Header: '#',
      accessor: 'no',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Nama',
      accessor: 'users.name',
    },
    {
      Header: 'Kab/Kota',
      accessor: 'kota',
    },

    {
      Header: 'Kecamatan',
      accessor: 'kecamatan',
    },
    {
      Header: 'Kelurahan / Desa',
      accessor: 'kelurahan',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center gap-1">
          {row.original.status !== 'Menunggu Konfirmasi' && (
            <span
              className="d-flex btn bg-primary text-white rounded-circle p-0"
              onClick={() => {
                setModalData(row.original.keterangan);
                setShowModal(true);
              }}
            >
              <iconify-icon icon="bi:info" width="20"></iconify-icon>
            </span>
          )}

          <span
            className={`badge rounded-pill ${
              row.original.status === 'Menunggu Konfirmasi'
                ? 'bg-warning'
                : row.original.status === 'Disetujui Kota/Kabupaten' ||
                  row.original.status === 'Disetujui Provinsi'
                ? 'bg-success'
                : 'bg-danger'
            }`}
          >
            {row.original.status}
          </span>
        </div>
      ),
    },
    {
      Header: 'Aksi',
      accessor: 'aksi',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          <div className="d-flex text-center">
            <Link
              to={`../detail-pengajuan/${row.original.id}`}
              className="btn btn-sm btn-primary me-2 d-flex align-items-center"
            >
              <iconify-icon inline icon="mdi:eye" width="20"></iconify-icon>
            </Link>
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                size="sm"
                id="dropdown-basic"
                className="d-flex align-items-center"
              >
                <iconify-icon inline icon="mdi:menu" width="20"></iconify-icon>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`../edit-pengajuan/${row.original.id}`}
                >
                  Edit
                </Dropdown.Item>

                {authUser.id === row.original.users.id && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      MySwal.fire({
                        title: 'Apakah anda yakin?',
                        html: `Anda akan menghapus pengajuan ini!`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        confirmButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                        preConfirm: async () => {
                          try {
                            const res = await apiClient.post(
                              `/psu/pengajuan/${row.original.id}}`
                            );
                            queryClient.invalidateQueries('psu');
                            return res.data;
                          } catch (err) {
                            MySwal.showValidationMessage(
                              `Request failed: ${err}`
                            );
                          }
                        },
                        allowOutsideClick: () => !MySwal.isLoading(),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          MySwal.fire(
                            'Terhapus!',
                            'Data berhasil dihapus.',
                            'success'
                          );
                        }
                      });
                    }}
                  >
                    Hapus
                  </Dropdown.Item>
                )}

                {/* <Dropdown.Item
                  as={'button'}
                  onClick={() => console.log('cetak')}
                >
                  Cetak Laporan
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ),
    },
  ]);

  tableModel.setRecords(tableData);

  return (
    <>
      <div className="mt-5">
        <CsTable
          columns={tableModel.columns}
          data={tableModel.data}
          loading={isFetching}
        />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Keterangan
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col">
              <input
                type={'text'}
                className="form-control-plaintext"
                defaultValue={modalData ?? '-'}
                readOnly
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TablePengajuan;
