import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import apiClient from '../../api/axios';
import { authUserAtom, tokenAtom } from '../../store/jotai';
import Layout from '../Layout';
import Loading from '../Loading';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const RequireAuth = () => {
  const [token, setToken] = useAtom(tokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);

  const currentAuth = useQuery(
    'checkAuth',
    () => {
      console.log('checkAuth');
      return apiClient.post('/auth/me');
    },
    {
      enabled: !!token,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,

      onSuccess: (res) => {
        setAuthUser((prev) => ({ ...res.data, roles: prev.roles }));
      },
      onError: (err) => {
        setToken(RESET);
        setAuthUser(RESET);

        MySwal.fire({
          title: 'Gagal!',
          text: 'Authentikasi Gagal, Silahkan Login Kembali',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      },
    }
  );

  if (currentAuth.isLoading) return <Loading />;

  return currentAuth.data ? <Layout /> : <Navigate to="/" />;
};

export default RequireAuth;
