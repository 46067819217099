import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import TitlePage from '../../../components/TitlePage';
import { authUserAtom } from '../../../store/jotai';
import TablePengajuan from './table-pengajuan';

const PengajuanPsu = () => {
  const [authUser] = useAtom(authUserAtom);
  return (
    <>
      <TitlePage
        title={'Pengajuan PSU'}
        subtitle={'Data pengajuan PSU yang telah diajukan oleh masyarakat'}
      >
        {authUser?.roles.find((role) =>
          ['kepala_desa', 'admin_provinsi'].includes(role)
        ) && (
          <Link to={'../form-pengajuan'} className="btn btn-primary">
            <span className="mx-4">Tambah Pengajuan</span>
          </Link>
        )}
      </TitlePage>

      <TablePengajuan />
    </>
  );
};

export default PengajuanPsu;
