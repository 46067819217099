import { useAtom } from 'jotai';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiClient from '../../../api/axios';
import TitlePage from '../../../components/TitlePage';
import { UseError } from '../../../hooks/useError';
import {
  authUserAtom,
  fasilitasUmumAtom,
  infrastrukturAtom,
  kondisiAtom,
  kotaAtom,
  statusJalanAtom,
} from '../../../store/jotai';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { RESET } from 'jotai/utils';

const MySwal = withReactContent(Swal);

const FormPsu = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({});

  const [infrastrukturList] = useAtom(infrastrukturAtom);
  const [statusJalanList] = useAtom(statusJalanAtom);
  const [kondisiList] = useAtom(kondisiAtom);
  const [fasilitasUmumList] = useAtom(fasilitasUmumAtom);

  const [kotaList, setKotaList] = useAtom(kotaAtom);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const getKota = useQuery(
    'getKota',
    () => {
      return apiClient.get('/show/kota');
    },
    {
      enabled: !authUser.kota_id,
      retry: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKotaList(data.data);
      },
    }
  );

  const getKecamatan = useQuery(
    ['getKecamatan', formData.kota_id || authUser.kota_id],
    () => {
      const kota = formData.kota_id || authUser.kota_id;
      return apiClient.get(`/show/kecamatan/${kota}`);
    },
    {
      enabled:
        authUser.role !== 'kepala_desa' &&
        (!!authUser.kota_id || !!formData?.kota_id),
      retry: true,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan'],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!formData?.kecamatan_id,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  // get value from checkbox
  const [fasilitas, setFasilitas] = useState([]);
  const handleFasilitas = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setFasilitas([...fasilitas, value]);
    } else {
      setFasilitas(fasilitas.filter((item) => item !== value));
    }
  };

  const postPengajuan = useMutation(
    ['postPengajuan'],
    (e) => {
      e.preventDefault();

      if (authUser.role === 'kepala_desa') {
        formData['kecamatan_id'] = authUser.kecamatan_id;
        formData['kelurahan_id'] = authUser.kelurahan_id;
      }

      formData.fasilitas_umum = fasilitas;

      return apiClient.post('/psu/pengajuan/kirim', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: (data) => {
        navigate('/psu/pengajuan');

        MySwal.fire({
          title: 'Berhasil!',
          text: 'Pengajuan berhasil dikirim',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Form Pengajuan'}
        subtitle={'Data pengajuan PSU yang telah diajukan oleh masyarakat'}
      />

      <form className="mb-4" onSubmit={postPengajuan.mutate}>
        <div className="d-grid gap-4 mb-4">
          <div className="row">
            <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

            {authUser?.roles.find((role) =>
              ['admin_provinsi'].includes(role)
            ) && (
              <div className="col-3 mb-3">
                <label htmlFor="kota" className="form-label">
                  Kabupaten/Kota :
                </label>
                <Form.Select
                  className="form-control p-2"
                  aria-label="Pilih Kecamatan"
                  name="kota_id"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    queryClient.removeQueries('getKecamatan');
                    setKelurahanList(RESET);
                  }}
                >
                  <option hidden value={''}>
                    Pilih Kabupaten/Kota
                  </option>
                  {getKota.isFetched &&
                    kotaList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            )}
            <div className="col-3 mb-3">
              <label htmlFor="kecamatan" className="form-label">
                Kecamatan :
              </label>

              <Form.Select
                className="form-control p-2"
                aria-label="Pilih Kecamatan"
                name="kecamatan_id"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                  getKelurahan.mutate(e.target.value);
                }}
              >
                {authUser.role === 'kepala_desa' ? (
                  <option value={authUser.kecamatan_id}>
                    {authUser.kecamatan}
                  </option>
                ) : (
                  <>
                    <option hidden value={''}>
                      Pilih Kecamatan
                    </option>

                    {getKecamatan.isFetched ? (
                      kecamatanList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>
                        Pilih Kabupaten/Kota Terlebih Dahulu
                      </option>
                    )}
                  </>
                )}
              </Form.Select>
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="kelurahan" className="form-label">
                Kelurahan / Desa :
              </label>

              <Form.Select
                className="form-control p-2"
                aria-label="Pilih Kecamatan"
                name="kelurahan_id"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                {authUser.role === 'kepala_desa' ? (
                  <option hidden value={authUser.kelurahan_id}>
                    {authUser.kelurahan}
                  </option>
                ) : (
                  <>
                    <option hidden value={''}>
                      Pilih Kelurahan / Desa
                    </option>
                    {kelurahanList.length > 0 ? (
                      kelurahanList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>Pilih Kecamatan Terlebih Dahulu</option>
                    )}
                  </>
                )}
              </Form.Select>
            </div>

            <div className="col-1 mb-3">
              <label htmlFor="rt" className="form-label">
                RT :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="rt"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-1 mb-3">
              <label htmlFor="rw" className="form-label">
                RW :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="rw"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <span className="fw-semibold mb-3 h5">Usulan Fasilitas</span>

            <div className="col-3 mb-3">
              <label htmlFor="infrastruktur_id" className="form-label">
                Jenis Infrastruktur :
              </label>

              <Form.Select
                className="form-control p-2"
                aria-label="Pilih Jenis Infrastruktur"
                name="infrastruktur_id"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option hidden value={''}>
                  Pilih Jenis Infrastruktur
                </option>
                {infrastrukturList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className="row">
            <div className="col-4 mb-3">
              <label htmlFor="nama_ruas" className="form-label">
                Nama Ruas Jalan :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="nama_ruas"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="panjang" className="form-label">
                Panjang (m) :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="panjang"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="lebar" className="form-label">
                Lebar (m) :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="lebar"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="status_jalan" className="form-label">
                Status Jalan :
              </label>

              <Form.Select
                className="form-control p-2"
                aria-label="Pilih Status Jalan"
                name="status_jalan"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              >
                <option hidden value={''}>
                  Pilih Status Jalan
                </option>
                {statusJalanList.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="kondisi" className="form-label">
                Kondisi :
              </label>

              <Form.Select
                className="form-control p-2"
                aria-label="Pilih Kondisi"
                name="kondisi"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              >
                <option hidden value={''}>
                  Pilih Kondisi
                </option>
                {kondisiList.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="lhr_motor" className="form-label">
                Lalu Lintas Harian Motor :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="lhr_motor"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-2 mb-3">
              <label htmlFor="lhr_mobil" className="form-label">
                Lalu Lintas Harian Mobil :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="lhr_mobil"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="tgl_kanan" className="form-label">
                Tata Guna Lahan Kanan :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="tgl_kanan"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="tgl_kiri" className="form-label">
                Tata Guna Lahan Kiri :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="tgl_kiri"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4 mb-3">
              <label htmlFor="fasilitas_umum" className="form-label">
                Fasilitas Umum :
              </label>

              <div className="form-control p-2">
                {fasilitasUmumList.map((item, index) => (
                  <div className="form-check form-check-inline" key={index}>
                    <input
                      id={`inlineCheckbox${index}`}
                      className="form-check-input"
                      type="checkbox"
                      key={index}
                      name="fasilitas_umum[]"
                      value={item.name}
                      onChange={(e) => {
                        handleFasilitas(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`inlineCheckbox${index}`}
                    >
                      {item.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3 mb-3">
              <label htmlFor="proposal" className="form-label">
                Proposal :
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="form-control p-2"
                name="proposal"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  })
                }
              />
            </div>

            <div className="col-5 mb-3">
              <label htmlFor="deskripsi_proposal" className="form-label">
                Deskripsi Proposal :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="deskripsi_proposal"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4 mb-3">
              <label htmlFor="pernyataan_hibah" className="form-label">
                Surat Pernyataan Siap Menerima Hibah :
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="form-control p-2"
                name="pernyataan_hibah"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  })
                }
              />
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="sketch" className="form-label">
                Sketch Lokasi (Gambar) :
              </label>
              <input
                type="file"
                accept="image/*"
                className="form-control p-2"
                name="sketch"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3 mb-3">
              <label htmlFor="bebas_sengketa" className="form-label">
                Wilayah Bebas Sengketa :
              </label>

              <div className="form-control p-2">
                <div className="form-check form-check-inline">
                  <input
                    id="bebas_sengketa_ya"
                    className="form-check-input"
                    type="radio"
                    name="bebas_sengketa"
                    value="ya"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="bebas_sengketa_ya"
                  >
                    Ya
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="bebas_sengketa_tidak"
                    className="form-check-input"
                    type="radio"
                    name="bebas_sengketa"
                    value="tidak"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="bebas_sengketa_tidak"
                  >
                    Tidak
                  </label>
                </div>
              </div>
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="safe_zone" className="form-label">
                Bukan Wilayah Konservasi :
              </label>

              <div className="form-control p-2">
                <div className="form-check form-check-inline">
                  <input
                    id="safe_zone_ya"
                    className="form-check-input"
                    type="radio"
                    name="safe_zone"
                    value="ya"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="safe_zone_ya">
                    Ya
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="safe_zone_tidak"
                    className="form-check-input"
                    type="radio"
                    name="safe_zone"
                    value="tidak"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="safe_zone_tidak">
                    Tidak
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary px-5 py-2">
          <span className="fs-5">Submit</span>
        </button>
      </form>
    </>
  );
};

export default FormPsu;
