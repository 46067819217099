import { Link } from 'react-router-dom';
import TitlePage from '../../../components/TitlePage';
import TablePengajuan from './table-pengajuan';

const Pengajuan = () => {
  return (
    <>
      <TitlePage
        title={'Pengajuan RUTIYANI'}
        subtitle={'Data pengajuan RUTIYANI yang telah diajukan oleh masyarakat'}
      >
        <Link to={'../form-pengajuan'} className="btn btn-primary py-2">
          <span className="mx-4">Tambah Pengajuan</span>
        </Link>
      </TitlePage>

      <TablePengajuan />
    </>
  );
};

export default Pengajuan;
