import { useAtom } from 'jotai';
import { authUserAtom } from '../store/jotai';
import Breadcrumb from './Breadcrumb';

const Header = () => {
  const [authUser] = useAtom(authUserAtom);
  return (
    <>
      <div
        className={
          'navbar-top bg-primary px-4 d-flex align-items-center justify-content-between'
        }
        style={{ height: 70 }}
      >
        <div className="d-flex align-items-center">
          <h4 className="fw-bold mb-0 text-white">SITUAN PERKIM</h4>
          <div>
            {/* badge */}

            <span className="badge rounded-pill bg-dark ms-2">
              {authUser?.role === 'admin_provinsi'
                ? 'Admin Provinsi'
                : authUser?.role === 'admin_kota'
                ? 'Admin Kabupaten/Kota'
                : 'Admin Kelurahan/Desa'}
            </span>
          </div>
        </div>
        <div className=" align-items-center">
          <Breadcrumb />
        </div>
      </div>
    </>
  );
};
export default Header;
