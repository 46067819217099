import { useNavigate } from 'react-router-dom';

const Forbidden = () => {
  const navigate = useNavigate();

  return (
    <div className="vh-100">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <p className="text-black-50 fs-1 fw-light mb-0">403</p>
          <p className="text-black-50 fs-3">Forbidden Access</p>
          <button
            className="btn btn-secondary"
            onClick={() => navigate('/dashboard')}
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
