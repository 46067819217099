import { useAtom } from 'jotai';
import { Navigate, Outlet } from 'react-router-dom';
import { authUserAtom, tokenAtom } from '../../store/jotai';

const RequireRoles = ({ allowedRoles }) => {
  const [authUser] = useAtom(authUserAtom);
  const [token] = useAtom(tokenAtom);

  if (!token) {
    return <Navigate to={'/'} />;
  }

  return authUser?.roles?.find((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={'/forbidden'} />
  );
};

export default RequireRoles;
