import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const tokenAtom = atomWithStorage('token', null);
export const authUserAtom = atomWithStorage('authUser', {});

export const jabatanAtom = atom([
  { id: 1, name: 'Kepala Desa' },
  { id: 2, name: 'Sekretaris Desa' },
  { id: 3, name: 'Lurah' },
  { id: 4, name: 'Sekretaris Lurah' },
  { id: 5, name: 'jabatan' },
  { id: 6, name: 'Pak RT' },
]);

export const penerimaManfaatAtom = atom([
  { name: 'MBR' },
  { name: 'MBR di Perbatasan/Pulau Terpencil' },
  { name: 'Anggota Legiun Veteran' },
]);

export const pendatanganSuratAtom = atom([
  { name: 'Kepala Desa' },
  { name: 'Bupati' },
  { name: 'Walikota' },
]);

export const kotaAtom = atom();

export const statistikAtom = atom({});

export const infrastrukturAtom = atom([
  { id: 1, name: 'Jalan Lingkungan' },
  { id: 2, name: 'Jembatan Lingkungan' },
  { id: 3, name: 'Gorong-Gorong' },
  { id: 4, name: 'Saluran Drainase' },
]);

export const statusJalanAtom = atom([
  { name: 'Jl. Lingkungan' },
  { name: 'Jl. Poros Desa' },
  { name: 'Jl. Komplek' },
]);

export const kondisiAtom = atom([
  { name: 'Bagus' },
  { name: 'Rusak Ringan' },
  { name: 'Rusak Sedang' },
  { name: 'Rusak Berat' },
  { name: 'Belum Tersedia' },
]);

export const fasilitasUmumAtom = atom([
  { name: 'Fasilitas Pendidikan' },
  { name: 'Fasilitas Kesehatan' },
  { name: 'Tempat Ibadah' },
]);
