import { Link } from 'react-router-dom';
import TitlePage from '../../../components/TitlePage';
import TablePengajuan from './table-pengajuan';

const PengajuanRutena = () => {
  return (
    <>
      <TitlePage
        title={'Pengajuan Rutena'}
        subtitle={'Data pengajuan RUTENA yang telah diajukan oleh masyarakat'}
      >
        <Link to={'../form-pengajuan'} className="btn btn-primary">
          <span className="mx-4">Tambah Pengajuan</span>
        </Link>
      </TitlePage>

      <TablePengajuan />
    </>
  );
};

export default PengajuanRutena;
