import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import apiClient from '../../../api/axios';
import Loading from '../../../components/Loading';
import TitlePage from '../../../components/TitlePage';
import Dokumen from './Dokumen';
import LokasiJumlah from './LokasiJumlah';
import PengusulSurat from './PengusulSurat';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../../store/jotai';
import FotoPengajuan from './Foto';
import RutiyaniVerifikasi from '../../../components/Pengajuan/RutiyaniVerifikasi';

const DetailPengajuan = () => {
  const [authUser] = useAtom(authUserAtom);
  const { id } = useParams();

  const [data, setData] = useState();

  const { isFetching } = useQuery(
    ['rutiyaniDetail', id],
    () => {
      return apiClient.get(`/rutiyani/pengajuan/show/${id}`);
    },
    {
      enabled: !!id,
      retry: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setData(data.data.data);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Detail Pengajuan'}
        subtitle={'Berikut adalah detail pengajuan yang dipilih'}
      >
        <div>
          <span className="badge bg-secondary fs-6">{data?.status}</span>

          {authUser?.role !== 'kepala_desa' && (
            <>
              {authUser?.role === 'admin_kota' &&
                data?.status === 'Menunggu Konfirmasi' && (
                  <RutiyaniVerifikasi id={id} />
                )}

              {authUser?.role === 'admin_provinsi' &&
                data?.status === 'Disetujui Kota/Kabupaten' && (
                  <RutiyaniVerifikasi id={id} />
                )}
            </>
          )}
        </div>
      </TitlePage>

      <Tab.Container defaultActiveKey={'pengusul'}>
        <Nav variant="pills" className="mb-4">
          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="pengusul">Pengusul dan Surat</Nav.Link>
          </Nav.Item>
          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="lokasi">Lokasi</Nav.Link>
          </Nav.Item>
          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="dokumen">Dokumen</Nav.Link>
          </Nav.Item>

          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="foto">Foto</Nav.Link>
          </Nav.Item>
        </Nav>

        {isFetching ? (
          <Loading />
        ) : (
          <Tab.Content>
            <Tab.Pane eventKey="pengusul">
              <PengusulSurat data={data} />
            </Tab.Pane>
            <Tab.Pane eventKey="lokasi">
              <LokasiJumlah data={data} />
            </Tab.Pane>
            <Tab.Pane eventKey="dokumen">
              <Dokumen data={data} />
            </Tab.Pane>
            <Tab.Pane eventKey="foto">
              <FotoPengajuan data={data} />
            </Tab.Pane>
          </Tab.Content>
        )}
      </Tab.Container>
    </>
  );
};

export default DetailPengajuan;
