import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import apiClient from '../../../api/axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { UseError } from '../../../hooks/useError';
import { authUserAtom } from '../../../store/jotai';
import { useAtom } from 'jotai';

const MySwal = withReactContent(Swal);

const FotoPsu = (props) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({
    pengajuan_id: props.data.id,
  });

  let unit = [];
  for (let i = 1; i <= props.data.jumlah_usulan_rumah; i++) {
    if (props.data?.foto[i] === undefined) {
      unit.push(i);
    }
  }

  const uploadFoto = useMutation(
    'uploadFotoPsu',
    (e) => {
      e.preventDefault();

      let multipePhoto = [];
      for (let i = 0; i < formData.foto.length; i++) {
        multipePhoto.push(formData.foto[i]);
      }

      return apiClient.post(
        'psu/pengajuan/upload',
        {
          pengajuan_id: formData.pengajuan_id,
          unit: formData.unit,
          foto: multipePhoto,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    {
      onSuccess: () => {
        setShowModal(false);
        queryClient.invalidateQueries('psuDetail');
        MySwal.fire('Berhasil!', 'Data berhasil diverifikasi.', 'success');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      {props.data.users.id === authUser.id && (
        <button
          className="btn btn-secondary mb-4"
          onClick={() => setShowModal(true)}
        >
          Upload Foto
        </button>
      )}

      <Modal
        key={props.data.id}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Tambah Foto Pengajuan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2" onSubmit={uploadFoto.mutate}>
            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Foto</label>
              </div>
              <div className="col-7">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="form-control"
                  name="foto"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-7 offset-md-3">
                <button type="submit" className="btn btn-primary">
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {props.data?.foto.length > 0 && (
        <div className="row mb-3">
          <div className="col">
            <div className="card">
              <div className="card-header">
                Unit
                {props.data.users.id === authUser.id && (
                  <button
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => {
                      MySwal.fire({
                        title: 'Apakah anda yakin?',
                        html: `Anda akan menghapus Foto!`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        confirmButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                        preConfirm: async () => {
                          try {
                            const res = await apiClient.post(
                              'rutena/pengajuan/delete-foto-unit',
                              {
                                pengajuan_id: props.data.id,
                                unit: 1,
                              }
                            );
                            queryClient.invalidateQueries('psuDetail');
                            return res.data;
                          } catch (err) {
                            MySwal.showValidationMessage(
                              `Request failed: ${err}`
                            );
                          }
                        },
                        allowOutsideClick: () => !MySwal.isLoading(),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          MySwal.fire(
                            'Terhapus!',
                            'Data berhasil dihapus.',
                            'success'
                          );
                        }
                      });
                    }}
                  >
                    Hapus
                  </button>
                )}
              </div>

              <div className="col-12">
                <div className="row row-cols-1 row-cols-md-3 m-1 mb-2">
                  {props.data?.foto.map((item) => {
                    return (
                      <div className="col g-2" key={item.id}>
                        <div className="card">
                          <img
                            src={item.foto_url}
                            alt=""
                            className="img-fluid p-2"
                            key={item.id}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FotoPsu;
