import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import TitlePage from '../../components/TitlePage';
import ModalAdminKota from './ModalAdminKota';
import TableKades from './TableKades';

import TableUserKota from './TableUserKota';

const UserPage = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <TitlePage
        title={'Data Pengguna'}
        subtitle={'List data pengguna yang terdaftar'}
      >
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Tambah Admin Kab/Kota
        </button>
      </TitlePage>

      <Tab.Container defaultActiveKey={'kades'}>
        <Nav variant="pills" className="mb-4">
          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="kades">Kelurahan / Desa</Nav.Link>
          </Nav.Item>
          <Nav.Item className="border border-primary rounded-3 me-3">
            <Nav.Link eventKey="kota">Kabupaten / Kota</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="kades">
            <TableKades />
          </Tab.Pane>
          <Tab.Pane eventKey="kota">
            <TableUserKota />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <ModalAdminKota showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default UserPage;
