import CardDetail from '../../../components/CardDetail';
import KeteranganDetail from '../../../components/KeteranganDetail';

const PengusulSuratRutena = ({ data }) => {
  let fasilitasUmum = [];
  data.fasilitas_umum.map((item) => fasilitasUmum.push(item.fasilitas));

  return (
    <div className="d-grid gap-4">
      <CardDetail title={'Info Pengusul'}>
        <div className="col-6 pe-4">
          <KeteranganDetail
            title={'Nama Pengusul'}
            keterangan={data.users.name}
          />
          <KeteranganDetail
            title={'Jabatan Pengusul'}
            keterangan={data.users.jabatan ?? '-'}
          />
          <KeteranganDetail
            title={'Email Pengusul'}
            keterangan={data.users.email}
          />
        </div>

        <div className="col-6 ps-4">
          <KeteranganDetail
            title={'Nomor Telepon'}
            keterangan={data.users.nomor_hp}
          />
          <KeteranganDetail
            title={'Instansi/Lembaga'}
            keterangan={data.users.instansi}
          />
          <KeteranganDetail
            title={'Alamat Instansi/Lembaga'}
            keterangan={data.users.alamat_instansi}
          />
        </div>
      </CardDetail>

      <CardDetail title={'Infrastruktur'}>
        <div className="col-6 pe-4">
          <KeteranganDetail
            title={'Jenis Infrastruktur'}
            keterangan={data.infrastruktur}
          />
          <KeteranganDetail
            title={'Status Jalan'}
            keterangan={data.status_jalan}
          />
          <KeteranganDetail title={'Kondisi'} keterangan={data.kondisi} />
          <KeteranganDetail
            title={'Lalu Lintas Harian Motor'}
            keterangan={data.lhr_motor}
          />
          <KeteranganDetail
            title={'Lalu Lintas Harian Mobil'}
            keterangan={data.lhr_mobil}
          />
          <KeteranganDetail
            title={'Fasilitas'}
            keterangan={fasilitasUmum.join(', ')}
          />
          <KeteranganDetail
            title={'Wilayah Bebas Sengketa'}
            keterangan={data.bebas_sengketa}
          />
        </div>

        <div className="col-6 ps-4">
          <KeteranganDetail
            title={'Tanggal Pengajuan'}
            keterangan={data.tanggal_surat}
          />
          <KeteranganDetail title={'Nama Ruas'} keterangan={data.nama_ruas} />
          <KeteranganDetail
            title={'Panjang'}
            keterangan={`${data.panjang} m`}
          />
          <KeteranganDetail title={'Lebar'} keterangan={`${data.lebar} m`} />
          <KeteranganDetail
            title={'Tata Guna Lahan Kanan'}
            keterangan={data.tgl_kanan}
          />
          <KeteranganDetail
            title={'Tata Guna Lahan Kiri'}
            keterangan={data.tgl_kiri}
          />
          <KeteranganDetail
            title={'Bukan Wilayah Konservasi'}
            keterangan={data.safe_zone}
          />
        </div>
      </CardDetail>
    </div>
  );
};

export default PengusulSuratRutena;
