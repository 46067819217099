import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { GlobalFilter } from './GlobalFilter';

const CsTable = (props) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-hover">
          <thead>
            <tr>
              <th colSpan={columns.length}>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </th>
            </tr>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <iconify-icon
                            icon={'ph:arrow-down'}
                            width={22}
                          ></iconify-icon>
                        ) : (
                          <iconify-icon
                            icon={'ph:arrow-up'}
                            width={22}
                          ></iconify-icon>
                        )
                      ) : (
                        column.canSort && (
                          <iconify-icon
                            icon={'ph:arrows-down-up-thin'}
                            width={22}
                          ></iconify-icon>
                        )
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {props.loading ? (
              // loading data
              <tr>
                <td colSpan={props.columns.length} className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : props.data.length < 1 ? (
              // no data
              <tr>
                <td colSpan={props.columns.length} className="text-center">
                  <span className="text-muted">Tidak ada data</span>
                </td>
              </tr>
            ) : (
              // data
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div className="col-3">
          <div className="d-flex align-items-center">
            <div className="col">
              <select
                className="px-2 form-select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Tampil {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="col ms-2">
              dari <span className="fw-semibold">{rows.length}</span> data
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <span className="mx-3">
            Halaman{' '}
            <span className="fw-semibold">
              {pageIndex + 1} dari {pageOptions.length}
            </span>
          </span>

          <nav>
            <ul className="pagination mb-0 g-2">
              <li className={`page-item ${!canPreviousPage && 'disabled'}`}>
                <Link className="page-link" onClick={() => gotoPage(0)}>
                  {'<<'}
                </Link>
              </li>

              <div className="d-flex mx-2">
                <li className={`page-item ${!canPreviousPage && 'disabled'}`}>
                  <Link className="page-link" onClick={() => previousPage()}>
                    {'<'}
                  </Link>
                </li>
                <li className={`page-item ${!canNextPage && 'disabled'}`}>
                  <Link className="page-link" onClick={() => nextPage()}>
                    {'>'}
                  </Link>
                </li>
              </div>
              <li className={`page-item ${!canNextPage && 'disabled'}`}>
                <Link
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                >
                  {'>>'}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default CsTable;
