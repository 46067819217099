const Dokumen = (props) => {
  return (
    <>
      <div className="row dokumen">
        <div className="col-6 mb-3">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              props.data.dokumen_permohonan
                ? window.open(props.data.dokumen_permohonan_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>Surat Permohonan Bupati / Kota</span>
                {!props.data.dokumen_permohonan && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>

        <div className="col-6">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              props.data.dokumen_sk
                ? window.open(props.data.dokumen_sk_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>Dokumen SK</span>
                {!props.data.dokumen_sk && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>

        <div className="col-6">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              props.data.dokumen_rekomendasi
                ? window.open(props.data.dokumen_rekomendasi_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>Surat Rekomendasi</span>
                {!props.data.dokumen_rekomendasi && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>

        <div className="col-6">
          <button
            className="card rounded-3 w-100"
            onClick={() => {
              props.data.dokumen_sk_kumuh
                ? window.open(props.data.dokumen_sk_kumuh_url, '_blank')
                : alert('Dokumen tidak ada');
            }}
          >
            <div className="card-body d-flex align-items-center">
              <span className="badge bg-primary rounded-5 p-1 me-3">
                <iconify-icon
                  class="p-2"
                  icon={'akar-icons:file'}
                  width={26}
                ></iconify-icon>
              </span>
              <div className="d-flex flex-column align-items-start">
                <span>SK Kawasan Kumuh</span>
                {!props.data.dokumen_sk_kumuh && (
                  <span className={'badge bg-danger'}>
                    Dokumen Belum Lengkap
                  </span>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-6 mb-3">
          <h6>Dokumen yang harus dilengkapi :</h6>
          <ol className="list-group list-group-numbered">
            <li className="list-group-item">Surat Permohonan</li>
            <li className="list-group-item">Dokumen SK</li>
            <li className="list-group-item">
              Surat Rekomendasi (Diinput oleh Admin Kab/Kota)
            </li>
            <li className="list-group-item">
              SK Kawasan Kumuh (Diinput oleh Admin Kab/Kota)
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Dokumen;
