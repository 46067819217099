import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const UseError = (error) => {
  if (error.response) {
    const errMsg = [];
    let errors = error?.response?.data?.errors;
    errors &&
      Object.keys(errors).forEach((e) => {
        errMsg.push(errors[e][0]);
      });
    const message =
      errMsg.length > 0
        ? errMsg.join('\n')
        : error.response.data?.message
        ? error.response.data?.message
        : error.response.data.error;
    MySwal.fire({
      title: 'Gagal!',
      text: message,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  } else {
    MySwal.fire({
      title: 'Gagal!',
      text: 'Terjadi kesalahan, silahkan coba lagi.',
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }
};
