import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import TitlePage from '../../components/TitlePage';
import { GetStatistik } from '../../hooks/useQueryStatistik';
import { statistikAtom } from '../../store/jotai';

const PSU = () => {
  const [statistik] = useAtom(statistikAtom);

  const getStatistik = GetStatistik();

  return (
    <>
      <TitlePage
        title={'PSU'}
        subtitle={'Berikut adalah beberapa data statistik dari pengajuan PSU'}
      />

      {getStatistik.isFetched && (
        <div className="row">
          <div className="col">
            <div
              className="card rounded-4 bg-primary text-white card-primary"
              style={{ height: '8rem' }}
            >
              <div className="card-body d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="card-title fw-light mb-0">Total Pengajuan</h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.total_pengajuan_psu ?? 0}
                  </h1>
                </div>
                <Link
                  to="/psu/pengajuan"
                  className="btn btn-sm btn-outline-light"
                >
                  Lihat Pengajuan
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card rounded-4" style={{ height: '8rem' }}>
              <div className="card-body d-flex align-items-center ">
                <div>
                  <h6 className="card-title fw-light mb-0">
                    Disetujui Provinsi
                  </h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.psu?.total_pengajuan_disetujui_provinsi ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card rounded-4" style={{ height: '8rem' }}>
              <div className="card-body d-flex align-items-center ">
                <div>
                  <h6 className="card-title fw-light mb-0">Ditolak Provinsi</h6>
                  <h1 className="card-subtitle fw-bold">
                    {statistik.psu?.total_pengajuan_ditolak_provinsi ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PSU;
