import CardDetail from '../../../components/CardDetail';
import KeteranganDetail from '../../../components/KeteranganDetail';

const PengusulSuratRutena = ({ data }) => (
  <div className="d-grid gap-4">
    <CardDetail title={'Info Pengusul'}>
      <div className="col-6 pe-4">
        <KeteranganDetail
          title={'Nama Pengusul'}
          keterangan={data.users.name}
        />
        <KeteranganDetail
          title={'Jabatan Pengusul'}
          keterangan={data.users.jabatan ?? '-'}
        />
        <KeteranganDetail
          title={'Email Pengusul'}
          keterangan={data.users.email}
        />
      </div>

      <div className="col-6 ps-4">
        <KeteranganDetail
          title={'Nomor Telepon'}
          keterangan={data.users.nomor_hp}
        />
        <KeteranganDetail
          title={'Instansi/Lembaga'}
          keterangan={data.users.instansi}
        />
        <KeteranganDetail
          title={'Alamat Instansi/Lembaga'}
          keterangan={data.users.alamat_instansi}
        />
      </div>
    </CardDetail>

    <CardDetail title={'Info Surat'}>
      <div className="col-6 pe-4">
        <KeteranganDetail title={'Nomor Surat'} keterangan={data.nomor_surat} />
        <KeteranganDetail
          title={'Tanggal Surat'}
          keterangan={data.tanggal_surat}
        />
        <KeteranganDetail
          title={'Penyediaan dan Rehabilitasi Rumah'}
          keterangan={data.penyediaan_rehabilitasi}
        />
      </div>

      <div className="col-6 ps-4">
        <KeteranganDetail
          title={'Jenis Bencana'}
          keterangan={data.jenis_kegiatan}
        />
        <KeteranganDetail
          title={'Penerima Manfaat'}
          keterangan={data.penerima_manfaat}
        />
      </div>
    </CardDetail>
  </div>
);

export default PengusulSuratRutena;
