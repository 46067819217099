import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import apiClient from '../../api/axios';
import CsTable from '../../components/CsTable';
import TableModel from '../../components/CsTable/Model/TableModel';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ModalKades from './ModalKades';
import { Dropdown } from 'react-bootstrap';

const TableKades = () => {
  const queryClient = useQueryClient();
  const MySwal = withReactContent(Swal);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();

  const [tableData, setTableData] = useState([]);

  const { isFetching } = useQuery(
    'kades',
    () => {
      return apiClient.get('/admin/users/kepala-desa');
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        setTableData(data.data.data);
      },
    }
  );

  const tableModel = new TableModel([
    {
      Header: '#',
      accessor: 'no',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Nama',
      accessor: 'name',
    },
    {
      Header: 'Jabatan',
      accessor: 'jabatan',
    },
    {
      Header: 'Kabupaten / Kota',
      accessor: 'kota',
    },
    {
      Header: 'Kecamatan',
      accessor: 'kecamatan',
    },
    {
      Header: 'Kelurahan',
      accessor: 'kelurahan',
    },
    {
      Header: 'Status',
      accessor: 'is_verified',
      Cell: ({ row }) => {
        const status =
          row.original.is_verified === 1
            ? ['Terverifikasi', 'bg-success']
            : ['Belum Diverifikasi', 'bg-danger'];
        return (
          <span className={`badge rounded-pill ${status[1]}`}>{status[0]}</span>
        );
      },
    },
    {
      Header: 'Aksi',
      accessor: 'aksi',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="d-flex gap-2 justify-content-center">
          {row.original.is_verified === 0 && (
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                MySwal.fire({
                  title: 'Apakah anda yakin?',
                  html: `Verifikasi akun <span class='fw-semibold'>${row.original.email}</span>!`,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Verify',
                  showLoaderOnConfirm: true,
                  preConfirm: async () => {
                    try {
                      const res = await apiClient.post(
                        '/admin/verifikasi/account',
                        {
                          email: row.original.email,
                        }
                      );
                      queryClient.invalidateQueries('kades');
                      return res.data;
                    } catch (err) {
                      MySwal.showValidationMessage(`Request failed: ${err}`);
                    }
                  },
                  allowOutsideClick: () => !MySwal.isLoading(),
                }).then((result) => {
                  if (result.isConfirmed) {
                    MySwal.fire(
                      'Verified!',
                      'Data berhasil diverifikasi.',
                      'success'
                    );
                  }
                });
              }}
            >
              <iconify-icon icon="fa-solid:check"></iconify-icon>
            </button>
          )}

          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setShowModal(true);
              setModalData(row.original.id);
            }}
          >
            <iconify-icon icon="fa-solid:eye"></iconify-icon>
          </button>

          {/* <button
            className="btn btn-sm btn-danger me-2"
            onClick={() => {
              MySwal.fire({
                title: 'Apakah anda yakin?',
                html: `Anda akan menghapus <span class='fw-semibold'>${row.original.name}</span>!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#d33',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                  try {
                    const res = await apiClient.post('/admin/delete-account', {
                      id: row.original.id,
                    });
                    queryClient.invalidateQueries('kades');
                    return res.data;
                  } catch (err) {
                    MySwal.showValidationMessage(`Request failed: ${err}`);
                  }
                },
                allowOutsideClick: () => !MySwal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  MySwal.fire('Terhapus!', 'Data berhasil dihapus.', 'success');
                }
              });
            }}
          >
            <iconify-icon icon={'fa-solid:trash-alt'}></iconify-icon>
          </button> */}

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              size="sm"
              id="dropdown-basic"
              className="d-flex align-items-center"
            >
              <iconify-icon inline icon="mdi:menu" width="20"></iconify-icon>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  MySwal.fire({
                    title: 'Apakah anda yakin?',
                    html: `Anda akan Reset Password <span class='fw-semibold'>${row.original.name}</span>!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Reset',
                    confirmButtonColor: '#d33',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                      try {
                        const res = await apiClient.post(
                          '/admin/reset-password',
                          {
                            id: row.original.id,
                          }
                        );
                        queryClient.invalidateQueries('kades');
                        return res.data;
                      } catch (err) {
                        MySwal.showValidationMessage(`Request failed: ${err}`);
                      }
                    },
                    allowOutsideClick: () => !MySwal.isLoading(),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      MySwal.fire(
                        'Reset Berhasil!',
                        'Password sementara',
                        'success'
                      );
                    }
                  });
                }}
              >
                Reset Password
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={() => {
                  MySwal.fire({
                    title: 'Apakah anda yakin?',
                    html: `Anda akan menghapus <span class='fw-semibold'>${row.original.name}</span>!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: '#d33',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                      try {
                        const res = await apiClient.post(
                          '/admin/delete-account',
                          {
                            id: row.original.id,
                          }
                        );
                        queryClient.invalidateQueries('kades');
                        return res.data;
                      } catch (err) {
                        MySwal.showValidationMessage(`Request failed: ${err}`);
                      }
                    },
                    allowOutsideClick: () => !MySwal.isLoading(),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      MySwal.fire(
                        'Terhapus!',
                        'Data berhasil dihapus.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Hapus
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ]);

  tableModel.setRecords(tableData);

  return (
    <>
      <CsTable
        columns={tableModel.columns}
        data={tableModel.data}
        loading={isFetching}
      />

      <ModalKades
        showModal={showModal}
        setShowModal={setShowModal}
        data={modalData}
      />
    </>
  );
};

export default TableKades;
