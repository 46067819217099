import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import apiClient from '../api/axios';
import { statistikAtom } from '../store/jotai';

export const GetStatistik = (year = 2023) => {
  const [, setStatistik] = useAtom(statistikAtom);

  const getStatistik = useQuery(
    'statistik',
    () => {
      return apiClient.get('statistik/pengajuan');
    },
    {
      onSuccess: (data) => {
        setStatistik(data.data);
      },
    }
  );

  return getStatistik;
};
