import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import apiClient from '../../api/axios';
import { UseError } from '../../hooks/useError';
import { UseSuccess } from '../../hooks/useSuccess';

const ModalGantiPassword = (props) => {
  const [formData, setFormData] = useState({});

  const gantiPassword = useMutation(
    'gantiPassword',
    (e) => {
      e.preventDefault();
      return apiClient.post('/account/change-password', formData);
    },
    {
      onSuccess: (data) => {
        props.setShowModal(false);
        setFormData({});
        UseSuccess('Password berhasil diganti.');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <Modal
        size="lg"
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Ganti Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2" onSubmit={gantiPassword.mutate}>
            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Password Lama
                </label>
              </div>
              <div className="col-7">
                <input
                  type="password"
                  className="form-control"
                  name="current_password"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Password Baru
                </label>
              </div>
              <div className="col-7">
                <input
                  type="password"
                  className="form-control"
                  name="new_password"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-4 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Konfirmasi Password
                </label>
              </div>
              <div className="col-7">
                <input
                  type="password"
                  className="form-control"
                  name="new_confirm_password"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-7 offset-md-4">
                <div className="d-flex align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    disabled={gantiPassword.isLoading}
                  >
                    Simpan
                  </button>
                  {gantiPassword.isLoading && (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalGantiPassword;
