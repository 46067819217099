import { useAtom } from 'jotai';
import DashboardCard from '../../components/DashboardCard';
import TitlePage from '../../components/TitlePage';
import { authUserAtom, statistikAtom } from '../../store/jotai';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';
import { GetStatistik } from '../../hooks/useQueryStatistik';
import { useState } from 'react';
import ModalGantiPassword from './ModalGantiPassword';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [statistik] = useAtom(statistikAtom);

  const getStatistik = GetStatistik();

  const [authUser] = useAtom(authUserAtom);
  const data = {
    labels: ['RUTIYANI', 'RUTENA', 'PSU'],
    datasets: [
      {
        label: 'Jumlah Pengajuan',
        data: [
          statistik.total_pengajuan_rutiyani,
          statistik.total_pengajuan_rutena,
          statistik.total_pengajuan_psu,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <h5 className="fw-light">Halo, {authUser?.name}</h5>
      <TitlePage
        title={'Dashboard'}
        subtitle={
          'Berikut adalah beberapa menu pengajuan pada aplikasi SITUAN PERKIM'
        }
      >
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Ganti Password
        </button>

        <ModalGantiPassword showModal={showModal} setShowModal={setShowModal} />
      </TitlePage>

      {getStatistik.isFetched && (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <DashboardCard
                title={'Pengajuan Rutiyani'}
                subtitle={'Permohonan Bantuan Rumah Tidak Layak Huni'}
                icon={'akar-icons:home'}
                link={'/rutiyani'}
              />
            </div>

            <div className="col-4">
              <DashboardCard
                title={'Pengajuan RUTENA'}
                subtitle={'Permohonan Bantuan Rumah Terkena Bencana'}
                icon={'ci:home-alt-x'}
                link={'/rutena'}
              />
            </div>

            <div className="col-4">
              <DashboardCard
                title={'Pengajuan PSU'}
                subtitle={'Permohonan Prasarana Sarana Utilitas Umum'}
                icon={'el:road'}
                link={'/psu'}
              />
            </div>

            <div className="col-4 offset-md-4 mt-3">
              <Pie
                data={data}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                      align: 'center',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
