import TitlePage from '../../../components/TitlePage';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAtom } from 'jotai';
import {
  authUserAtom,
  kotaAtom,
  pendatanganSuratAtom,
} from '../../../store/jotai';
import apiClient from '../../../api/axios';
import { UseError } from '../../../hooks/useError';
import contoh from '../../../assets/doc/Surat Permohonan Rutena.docx';
import { RESET } from 'jotai/utils';

const FormPengajuanRutena = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({});

  const [kotaList, setKotaList] = useAtom(kotaAtom);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const [date, setDate] = useState(null);
  const [pendatangan_surat] = useAtom(pendatanganSuratAtom);

  const getKota = useQuery(
    'getKota',
    () => {
      return apiClient.get('/show/kota');
    },
    {
      enabled: !authUser.kota_id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setKotaList(data.data);
      },
    }
  );

  const getKecamatan = useQuery(
    ['getKecamatan', formData.kota || authUser.kota_id],
    () => {
      const kota = formData.kota || authUser.kota_id;
      return apiClient.get(`/show/kecamatan/${kota}`);
    },
    {
      enabled: !!authUser.kota_id || !!formData?.kota,
      retry: true,
      onSuccess: (data) => {
        setKecamatanList(data.data);
      },
    }
  );

  const getKelurahan = useMutation(
    ['getKelurahan'],
    (kecamatanId) => {
      return apiClient.get(`/show/kelurahan/${kecamatanId}`);
    },
    {
      enabled: !!formData?.kecamatan_id,
      onSuccess: (data) => {
        setKelurahanList(data.data);
      },
    }
  );

  const postPengajuan = useMutation(
    'postPengajuan',
    (e) => {
      e.preventDefault();
      formData['penerima_manfaat'] = 'Masyarakat Terdampak Bencana';

      if (formData.tanggal_surat) {
        // format date to yyyy-mm-dd
        const date = new Date(formData?.tanggal_surat);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        formData['tanggal_surat'] = formattedDate;
      }

      return apiClient.post('rutena/pengajuan/kirim', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        navigate('/rutena/pengajuan');
        // mySwal on success
        MySwal.fire({
          title: 'Berhasil!',
          text: 'Pengajuan berhasil dikirim',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Form Pengajuan'}
        subtitle={'Data pengajuan RUTENA yang telah diajukan oleh masyarakat'}
      />

      <form className="mb-4" onSubmit={postPengajuan.mutate}>
        <div className="d-grid gap-4 mb-4">
          <div className="row">
            <span className="fw-semibold mb-3 h5">Info Surat</span>

            <div className="col-3 mb-3">
              <label htmlFor="nomor_surat" className="form-label">
                Nomor Surat :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="nomor_surat"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="nama" className="form-label">
                Tanggal Surat :
              </label>
              <ReactDatePicker
                className="form-control p-2"
                dateFormat={'yyyy-MM-dd'}
                selected={date}
                onChange={(date) => {
                  setDate(date);
                  setFormData({ ...formData, tanggal_surat: date });
                }}
              />
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="pendatangan_surat" className="form-label">
                Pendatangan Surat Usulan :
              </label>

              <Form.Select
                aria-label="Pilih Penerima Manfaat"
                name="pendatangan_surat"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
                value={formData?.pendatangan_surat}
              >
                <option hidden value={''}>
                  Pilih Pendatangan Surat
                </option>
                {pendatangan_surat.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-3 mb-3">
              <label htmlFor="jenis_kegiatan" className="form-label">
                Jenis Bencana :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="jenis_kegiatan"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="penyediaan_rehabilitasi" className="form-label">
                Penyediaan dan Rehabilitasi Rumah :
              </label>
              <input
                type="text"
                className="form-control p-2"
                name="penyediaan_rehabilitasi"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

            {authUser?.roles.find((role) =>
              ['admin_provinsi'].includes(role)
            ) && (
              <div className="col-3 mb-3">
                <label htmlFor="kota" className="form-label">
                  Kabupaten/Kota :
                </label>
                <Form.Select
                  aria-label="Pilih Kecamatan"
                  name="kota"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                    queryClient.removeQueries('getKecamatan');
                    setKelurahanList(RESET);
                  }}
                >
                  <option hidden value={''}>
                    Pilih Kabupaten/Kota
                  </option>
                  {getKota.isFetched &&
                    kotaList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            )}

            <div className="col-4 mb-3">
              <label htmlFor="nama" className="form-label">
                Kecamatan :
              </label>
              <Form.Select
                aria-label="Pilih Kecamatan"
                name="kecamatan"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                  getKelurahan.mutate(e.target.value);
                }}
              >
                <option hidden value={''}>
                  Pilih Kecamatan
                </option>
                {getKecamatan.isFetched ? (
                  kecamatanList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))
                ) : (
                  <option disabled>Pilih Kabupaten/Kota Terlebih Dahulu</option>
                )}
              </Form.Select>
            </div>

            <div className="col-4 mb-3">
              <label htmlFor="nama" className="form-label">
                Kelurahan / Desa :
              </label>
              <Form.Select
                aria-label="Pilih Kecamatan"
                name="kelurahan"
                onChange={(e) => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
                }}
              >
                <option hidden value={''}>
                  Pilih Kelurahan / Desa
                </option>
                {kelurahanList.length > 0 ? (
                  kelurahanList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))
                ) : (
                  <option disabled>Pilih Kecamatan Terlebih Dahulu</option>
                )}
              </Form.Select>
            </div>

            <div className="col-6 mb-3">
              <label htmlFor="jumlah_rumah_terdampak" className="form-label">
                Jumlah Rumah Terdampak :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="jumlah_rumah_terdampak"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>

            <div className="col-6 mb-3">
              <label htmlFor="jumlah_usulan_rumah" className="form-label">
                Jumlah Usulan Rumah Terdampak yang Dimohonkan :
              </label>
              <input
                type="number"
                className="form-control p-2"
                name="jumlah_usulan_rumah"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <span className="fw-semibold h5 mb-0">Upload Dokumen</span>
            <p className="fw-light" style={{ fontSize: 13 }}>
              Silahkan upload dokumen pada form ini dengan format file{' '}
              <span className="fw-semibold">PDF</span>
            </p>

            <div className="col-6 mb-3">
              <label htmlFor="dokumen_permohonan" className="form-label">
                Surat Permohonan Bupati / Kota :
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="form-control"
                name="dokumen_permohonan"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  })
                }
              />
              <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                Contoh Format Permohonan,{'  '}
                <a href={contoh} target={'_blank'} rel="noreferrer">
                  klik disini
                </a>
              </p>
              <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                Surat Permohonan ditunjukan kepada Gubernur Kalimantan Barat cq.
                Kepala Dinas Perumahan Rakyat dan Kawasan Permukiman Prov.
                Kalbar
              </p>
            </div>

            <div className="col-6 mb-3">
              <label htmlFor="dokumen_sk" className="form-label">
                Dokumen SK Kebencanaan Kab / Kota :
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="form-control"
                name="dokumen_sk"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.files[0],
                  })
                }
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary px-5 py-2">
          <span className="fs-5">Submit</span>
        </button>
      </form>
    </>
  );
};

export default FormPengajuanRutena;
