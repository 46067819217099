import CardDetail from '../../../components/CardDetail';
import KeteranganDetail from '../../../components/KeteranganDetail';

const LokasiRutena = ({ data }) => (
  <div className="d-grid gap-4">
    <CardDetail title={'Lokasi Pengusulan'}>
      <div className="col-6 pe-4">
        <KeteranganDetail title={'Provinsi'} keterangan={'Kalimantan Barat'} />

        <KeteranganDetail title={'Kabupaten/Kota'} keterangan={data.kota} />
        <KeteranganDetail title={'Kecamatan'} keterangan={data.kecamatan} />
        <KeteranganDetail title={'Desa/Kelurahan'} keterangan={data.kelurahan} />
      </div>

      <div className="col-6  ps-4">
        <KeteranganDetail
          title={'Jumlah Rumah Terdampak'}
          keterangan={data.jumlah_rumah_terdampak}
        />
        <KeteranganDetail
          title={'Jumlah Usulan Rumah Terdampak yang Dimohonkan'}
          keterangan={data.jumlah_usulan_rumah}
        />
      </div>
    </CardDetail>
  </div>
);

export default LokasiRutena;
