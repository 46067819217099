import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import '../../assets/css/card.css';
import TitlePage from '../../components/TitlePage';
import { statistikAtom } from '../../store/jotai';

const Rutena = () => {
  const [statistik] = useAtom(statistikAtom);
  return (
    <>
      <TitlePage
        title={'Rutena'}
        subtitle={
          'Berikut adalah beberapa data statistik dari pengajuan RUTENA'
        }
      >
        {/* <button className="btn btn-outline-secondary">2022</button> */}
      </TitlePage>

      <div className="row">
        <div className="col">
          <div
            className="card rounded-4 bg-primary text-white card-primary"
            style={{ height: '8rem' }}
          >
            <div className="card-body d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title fw-light mb-0">Total Pengajuan</h6>
                <h1 className="card-subtitle fw-bold">
                  {statistik.total_pengajuan_rutena}
                </h1>
              </div>
              <Link
                to="/rutena/pengajuan"
                className="btn btn-sm btn-outline-light"
              >
                Lihat Pengajuan
              </Link>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card rounded-4" style={{ height: '8rem' }}>
            <div className="card-body d-flex align-items-center ">
              <div>
                <h6 className="card-title fw-light mb-0">Disetujui Provinsi</h6>
                <h1 className="card-subtitle fw-bold">
                  {statistik.rutena?.total_pengajuan_disetujui_provinsi}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card rounded-4" style={{ height: '8rem' }}>
            <div className="card-body d-flex align-items-center ">
              <div>
                <h6 className="card-title fw-light mb-0">Ditolak Provinsi</h6>
                <h1 className="card-subtitle fw-bold">
                  {statistik.rutena?.total_pengajuan_ditolak_provinsi}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rutena;
