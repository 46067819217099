import { useAtom } from 'jotai';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiClient from '../../../api/axios';
import TitlePage from '../../../components/TitlePage';
import { UseError } from '../../../hooks/useError';
import {
  authUserAtom,
  fasilitasUmumAtom,
  kondisiAtom,
  statusJalanAtom,
} from '../../../store/jotai';

import { useParams } from 'react-router-dom';
import { UseSuccess } from '../../../hooks/useSuccess';
import Loading from '../../../components/Loading';
import ButtonAjukanKembali from '../../../components/Pengajuan/ButtonAjukanKembali';

const FormEditPsu = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState({});

  const [statusJalanList] = useAtom(statusJalanAtom);
  const [kondisiList] = useAtom(kondisiAtom);
  const [fasilitasUmumList] = useAtom(fasilitasUmumAtom);

  // get value from checkbox
  const [fasilitas, setFasilitas] = useState([]);

  const handleFasilitas = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setFasilitas([...fasilitas, value]);
    } else {
      setFasilitas(fasilitas.filter((item) => item !== value));
    }
  };

  const psuDetail = useQuery(
    ['psuDetail', id],
    () => {
      return apiClient.get(`/psu/pengajuan/show/${id}`);
    },
    {
      enabled: !!id,
      retry: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setFormData(data.data.data);
        const fasilitasChecked = [];
        data.data.data.fasilitas_umum.map((item) => {
          return fasilitasChecked.push(item.fasilitas);
        });
        setFasilitas([...fasilitas, ...fasilitasChecked]);
      },
    }
  );

  const updatePsu = useMutation(
    'updatePsu',
    (e) => {
      e.preventDefault();

      const form = new FormData();
      form.append('pengajuan_id', formData.id);
      form.append('nama_ruas', formData.nama_ruas);
      form.append('panjang', formData.panjang);
      form.append('lebar', formData.lebar);
      form.append('status_jalan', formData.status_jalan);
      form.append('kondisi', formData.kondisi);
      form.append('lhr_motor', formData.lhr_motor);
      form.append('lhr_mobil', formData.lhr_mobil);
      form.append('tgl_kiri', formData.tgl_kiri);
      form.append('tgl_kanan', formData.tgl_kanan);
      form.append('deskripsi_proposal', formData.deskripsi_proposal);
      form.append('bebas_sengketa', formData.bebas_sengketa);
      form.append('safe_zone', formData.safe_zone);

      fasilitas.map((item) => {
        return form.append('fasilitas_umum[]', item);
      });

      // return console.log(fasilitas);

      formData.proposal_file && form.append('proposal', formData.proposal_file);

      formData.pernyataan_hibah_file &&
        form.append('pernyataan_hibah', formData.pernyataan_hibah_file);

      formData.sketch_file && form.append('sketch', formData.sketch_file);

      return apiClient.post(`/psu/pengajuan/edit`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: (data) => {
        setFasilitas([]);
        UseSuccess('Data berhasil diupdate.');
        queryClient.invalidateQueries('psuDetail');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Form Pengajuan'}
        subtitle={'Data pengajuan PSU yang telah diajukan oleh masyarakat'}
      />

      {psuDetail.isFetching || updatePsu.isLoading ? (
        <Loading />
      ) : (
        <form className="mb-4" onSubmit={updatePsu.mutate}>
          <div className="d-grid gap-4 mb-4">
            <div className="row">
              <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

              <div className="col-3 mb-3">
                <label htmlFor="kota" className="form-label">
                  Kabupaten/Kota :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="kota"
                  defaultValue={formData?.kota}
                  disabled
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="kecamatan_id" className="form-label">
                  Kecamatan :
                </label>

                <input
                  type="text"
                  className="form-control p-2"
                  name="kecamatan_id"
                  defaultValue={formData?.kecamatan}
                  disabled
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="kelurahan_id" className="form-label">
                  Kelurahan / Desa :
                </label>

                <input
                  type="text"
                  className="form-control p-2"
                  name="kelurahan_id"
                  defaultValue={formData?.kelurahan}
                  disabled
                />
              </div>

              <div className="col-1 mb-3">
                <label htmlFor="rt" className="form-label">
                  RT :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="rt"
                  defaultValue={formData?.rt}
                  disabled
                />
              </div>

              <div className="col-1 mb-3">
                <label htmlFor="rw" className="form-label">
                  RW :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="rw"
                  defaultValue={formData?.rw}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <span className="fw-semibold mb-3 h5">Usulan Fasilitas</span>

              <div className="col-3 mb-3">
                <label htmlFor="infrastruktur_id" className="form-label">
                  Jenis Infrastruktur :
                </label>

                <input
                  type="text"
                  className="form-control p-2"
                  name="infrastruktur"
                  defaultValue={formData?.infrastruktur}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4 mb-3">
                <label htmlFor="nama_ruas" className="form-label">
                  Nama Ruas Jalan :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="nama_ruas"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.nama_ruas}
                />
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="panjang" className="form-label">
                  Panjang (m) :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="panjang"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.panjang}
                />
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="lebar" className="form-label">
                  Lebar (m) :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="lebar"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.lebar}
                />
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="status_jalan" className="form-label">
                  Status Jalan :
                </label>

                <Form.Select
                  className="form-control p-2"
                  aria-label="Pilih Status Jalan"
                  name="status_jalan"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={formData?.status_jalan}
                >
                  <option hidden value={''}>
                    Pilih Status Jalan
                  </option>
                  {statusJalanList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="kondisi" className="form-label">
                  Kondisi :
                </label>

                <Form.Select
                  className="form-control p-2"
                  aria-label="Pilih Kondisi"
                  name="kondisi"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={formData?.kondisi}
                >
                  <option hidden value={''}>
                    Pilih Kondisi
                  </option>
                  {kondisiList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="lhr_motor" className="form-label">
                  Lalu Lintas Harian Motor :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="lhr_motor"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.lhr_motor}
                />
              </div>

              <div className="col-2 mb-3">
                <label htmlFor="lhr_mobil" className="form-label">
                  Lalu Lintas Harian Mobil :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="lhr_mobil"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.lhr_mobil}
                />
              </div>

              <div className="col-4 mb-3">
                <label htmlFor="tgl_kanan" className="form-label">
                  Tata Guna Lahan Kanan :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="tgl_kanan"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.tgl_kanan}
                />
              </div>

              <div className="col-4 mb-3">
                <label htmlFor="tgl_kiri" className="form-label">
                  Tata Guna Lahan Kiri :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="tgl_kiri"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.tgl_kiri}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4 mb-3">
                <label htmlFor="fasilitas_umum" className="form-label">
                  Fasilitas Umum :
                </label>

                <div className="form-control p-2">
                  {fasilitasUmumList.map((item, index) => (
                    <div className="form-check form-check-inline" key={index}>
                      <input
                        id={`fasilitas_umum_${index}`}
                        className="form-check-input"
                        type="checkbox"
                        key={index}
                        name="fasilitas_umum[]"
                        value={item.name}
                        onChange={(e) => {
                          handleFasilitas(e);
                        }}
                        checked={fasilitas.includes(item.name)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`fasilitas_umum_${index}`}
                        id=""
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 mb-3">
                <label htmlFor="proposal" className="form-label">
                  Proposal :
                </label>
                <input
                  type="file"
                  accept="application/pdf"
                  className="form-control p-2"
                  name="proposal_file"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
              </div>

              <div className="col-5 mb-3">
                <label htmlFor="deskripsi_proposal" className="form-label">
                  Deskripsi Proposal :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="deskripsi_proposal"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.deskripsi_proposal}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4 mb-3">
                <label htmlFor="pernyataan_hibah" className="form-label">
                  Surat Pernyataan Siap Menerima Hibah :
                </label>
                <input
                  type="file"
                  accept="application/pdf"
                  className="form-control p-2"
                  name="pernyataan_hibah_file"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
              </div>

              <div className="col-4 mb-3">
                <label htmlFor="sketch" className="form-label">
                  Sketch Lokasi (Gambar) :
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control p-2"
                  name="sketch_file"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-3 mb-3">
                <label htmlFor="bebas_sengketa" className="form-label">
                  Wilayah Bebas Sengketa :
                </label>

                <div className="form-control p-2">
                  <div className="form-check form-check-inline">
                    <input
                      id="bebas_sengketa_ya"
                      className="form-check-input"
                      type="radio"
                      name="bebas_sengketa"
                      value="ya"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      defaultChecked={formData?.bebas_sengketa === 'Ya'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="bebas_sengketa_ya"
                    >
                      Ya
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="bebas_sengketa_tidak"
                      className="form-check-input"
                      type="radio"
                      name="bebas_sengketa"
                      value="tidak"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      defaultChecked={formData?.bebas_sengketa === 'Tidak'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="bebas_sengketa_tidak"
                    >
                      Tidak
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="safe_zone" className="form-label">
                  Bukan Wilayah Konservasi :
                </label>

                <div className="form-control p-2">
                  <div className="form-check form-check-inline">
                    <input
                      id="safe_zone_ya"
                      className="form-check-input"
                      type="radio"
                      name="safe_zone"
                      value="ya"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      defaultChecked={formData?.safe_zone === 'Ya'}
                    />
                    <label className="form-check-label" htmlFor="safe_zone_ya">
                      Ya
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="safe_zone_tidak"
                      className="form-check-input"
                      type="radio"
                      name="safe_zone"
                      value="tidak"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultChecked={formData?.safe_zone === 'Tidak'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="safe_zone_tidak"
                    >
                      Tidak
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <button type="submit" className="btn btn-primary px-5 py-2">
              <span className="fs-5">Submit</span>
            </button>
            {updatePsu.isLoading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {(formData?.status === 'Ditolak Provinsi' ||
              formData?.status === 'Ditolak Kota/Kabupaten') &&
              authUser.id === formData?.users.id && (
                <ButtonAjukanKembali id={id} kategori={'psu'} />
              )}
          </div>
        </form>
      )}
    </>
  );
};

export default FormEditPsu;
