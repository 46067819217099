import React from 'react';

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <>
      <div className="row justify-content-end">
        <div className="col-3 d-flex align-items-end flex-column">
          <label className="col-form-label fw-normal">Pencarian :</label>
        </div>
        <div className="col-3">
          <input
            placeholder="Cari..."
            type="text"
            className="form-control"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};
