import { Outlet } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';

const Layout = () => {
  return (
    <>
      <Navbar />

      <div className="wrapper-body" style={{ marginLeft: 80 }}>
        <Header />

        <div className="wrapper-content p-4">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
