import { Link } from 'react-router-dom';

const DashboardCard = (props) => (
  <div className="card rounded-4" style={{ height: '9rem' }}>
    <div className="card-body d-flex align-items-center ">
      <div>
        <span className="badge bg-primary rounded-3 p-1 mb-2">
          <iconify-icon
            class="p-1"
            icon={props.icon ?? 'mdi:home'}
            width={30}
          ></iconify-icon>
        </span>
        <h6 className="card-title fw-semibold">{props.title ?? 'title'}</h6>
        <p className="card-subtitle text-black-50" style={{ fontSize: 14 }}>
          {props.subtitle ?? 'subtitle'}
        </p>
        <Link to={props.link ?? '#'} className="stretched-link">
          <div
            className="mask"
            style={{
              background:
                'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
            }}
          ></div>
        </Link>
      </div>
    </div>
  </div>
);

export default DashboardCard;
