import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import RequireAuth from './components/Layout/requireAuth';

import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import Pengajuan from './pages/Rutiyani/Pengajuan';
import DetailPengajuan from './pages/Rutiyani/PengajuanDetail';
import FormPengajuan from './pages/Rutiyani/PengajuanForm';
import Rutiyani from './pages/Rutiyani';
import PengajuanRutena from './pages/Rutena/Pengajuan';
import FormPengajuanRutena from './pages/Rutena/PengajuanForm';
import DetailPengajuanRutena from './pages/Rutena/PengajuanDetail';
import Rutena from './pages/Rutena';
import UserPage from './pages/User';
import Forbidden from './components/Layout/Error/403';
import PSU from './pages/PSU';
import RequireRoles from './components/Layout/requireAdminProvinsi';
import EditPengajuan from './pages/Rutiyani/PengajuanEdit';
import EditPengajuanRutena from './pages/Rutena/PengajuanEdit';
import PengajuanPsu from './pages/PSU/Pengajuan';
import FormPsu from './pages/PSU/Form';
import DetailPsu from './pages/PSU/Detail';
import FormEditPsu from './pages/PSU/Edit';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<Landing />} />

            <Route element={<RequireAuth />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route
                element={<RequireRoles allowedRoles={['admin_provinsi']} />}
              >
                <Route path="users" element={<UserPage />} />
              </Route>

              <Route path="rutiyani">
                <Route index element={<Rutiyani />} />
                <Route path="pengajuan" element={<Pengajuan />} />

                <Route path="form-pengajuan" element={<FormPengajuan />} />
                <Route path="edit-pengajuan/:id" element={<EditPengajuan />} />
                <Route
                  path="detail-pengajuan/:id"
                  element={<DetailPengajuan />}
                />
              </Route>

              <Route
                element={
                  <RequireRoles
                    allowedRoles={['admin_provinsi', 'admin_kota']}
                  />
                }
              >
                <Route path="rutena">
                  <Route index element={<Rutena />} />
                  <Route path="pengajuan" element={<PengajuanRutena />} />
                  {/* <Route
                    element={<RequireRoles allowedRoles={['admin_kota']} />}
                  > */}
                  <Route
                    path="form-pengajuan"
                    element={<FormPengajuanRutena />}
                  />
                  <Route
                    path="edit-pengajuan/:id"
                    element={<EditPengajuanRutena />}
                  />
                  <Route
                    path="detail-pengajuan/:id"
                    element={<DetailPengajuanRutena />}
                  />
                </Route>
                {/* </Route> */}
              </Route>

              <Route path="psu">
                <Route index element={<PSU />} />
                <Route path="pengajuan" element={<PengajuanPsu />} />

                <Route path="form-pengajuan" element={<FormPsu />} />
                <Route
                  path="edit-pengajuan/:id"
                  element={<FormEditPsu />}
                />
                <Route path="detail-pengajuan/:id" element={<DetailPsu />} />
              </Route>
            </Route>

            <Route path="*" element={<h1>404 Not Found</h1>} />
            <Route path="/forbidden" element={<Forbidden />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
