import '../../assets/css/landing.css';

import logo from '../../assets/img/logo-new.png';
import logo_berakhlak from '../../assets/img/logo-berakhlak.png';
import logo_situan from '../../assets/img/logo-situanperkim.png';
import ilustrasi from '../../assets/img/landingpage.png';
import FormLogin from './FormLogin';
import { useState } from 'react';
import { Fade } from 'react-bootstrap';
import ModalRegister from './Register';

const Landing = () => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div id="landingpage">
      <div className="row h-100">
        <div className="col-md-12 position-fixed">
          <div className="row justify-content-between my-3">
            <div className="col-md-6 col-sm-12">
              <div className="d-flex ms-4 gap-2">
                <img
                  className="mb-2"
                  src={logo}
                  alt="situan-perkim"
                  height={40}
                />
                <img
                  className="mb-2"
                  src={logo_berakhlak}
                  alt="situan-perkim"
                  height={40}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <a
                    className="btn btn-outline-light py-2 ms-3"
                    href="https://bit.ly/situan-perkim"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <span className="d-flex align-items-center justify-content-center gap-2">
                      <iconify-icon
                        className=""
                        icon={'material-symbols:download-rounded'}
                        width={22}
                      ></iconify-icon>
                      Download Mobile/Android
                    </span>
                  </a>

                  <a
                    className="btn btn-success py-2"
                    href={'https://youtu.be/_Dt9-Wc486I'}
                    rel="noreferrer"
                  >
                    <span>Tutorial Aplikasi</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex h-100 align-items-center">
          <Fade in={!show}>
            <div
              id="titleHeader"
              className={
                'd-flex flex-column text-white px-5 animate__animated ' +
                (show ? ' d-none ' : ' ')
              }
            >
              <div className="d-flex">
                <img
                  className="me-1 my-1"
                  src={logo_situan}
                  alt="situan-perkim"
                  height={60}
                />
                <h1 className="heading">
                  <span
                    style={{
                      color: '#195ec5',
                      WebkitTextStroke: '3px #fff',
                    }}
                  >
                    SITUAN{' '}
                  </span>
                  PERKIM
                </h1>
              </div>
              <h6 className="subheading fs-3">
                Sistem Informasi Permohonan Bantuan Perumahan Rakyat dan Kawasan
                Permukiman Provinsi Kalimantan Barat
              </h6>

              <div className="row">
                <div className="d-flex align-items-center mt-3">
                  <button
                    className="btn btn-lg btn-light py-3 me-3 "
                    style={{ width: 200 }}
                    type="button"
                    onClick={() => setShow(!show)}
                  >
                    Login
                  </button>
                </div>
              </div>

              <p className="text-white mt-3">
                Kepala Desa/Lurah yang belum punya akun,{' '}
                <button
                  className="btn btn-link text-black-50 p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-daftar"
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Daftar disini!
                </button>
                <ModalRegister
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </p>
            </div>
          </Fade>

          <FormLogin open={show} setShow={setShow} />
        </div>

        <div className="col-lg-6 d-flex h-100 align-items-center justify-content-center">
          <img src={ilustrasi} className="w-75" alt="ilustrasi" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
