import { useQueryClient } from 'react-query';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import apiClient from '../../api/axios';

const MySwal = withReactContent(Swal);

const RutenaVerifikasi = (id) => {
  const queryClient = useQueryClient();

  const verifikasiPengajuan = (isVerified) => {
    const status =
      isVerified === 1 ? ['Disetujui', 'success'] : ['Ditolak', 'danger'];
    MySwal.fire({
      title: 'Verifikasi pengajuan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: status[0],
      confirmButtonColor: isVerified === 1 ? '#3A7BDA' : '#d33',
      input: isVerified === 0 ? 'textarea' : false,
      inputLabel: 'Keterangan',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await apiClient.post('/admin/verifikasi/pengajuan', {
            id,
            status_verifikasi: status[0],
            keterangan:
              isVerified === 0
                ? MySwal.getPopup().querySelector('textarea').value
                : null,
          });
          queryClient.invalidateQueries('rutenaDetail');
          return res.data;
        } catch (err) {
          MySwal.showValidationMessage(
            `Request failed: ${err?.response?.data?.message}`
          );
        }
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(`${status[0]}!`, 'Data berhasil diverifikasi.', 'success');
      }
    });
  };

  return (
    <>
      <button
        className="btn btn-success py-2 mx-2"
        onClick={() => {
          verifikasiPengajuan(1);
        }}
      >
        <span className="mx-4">Setuju</span>
      </button>
      <button
        className="btn btn-danger py-2 mx-2"
        onClick={() => verifikasiPengajuan(0)}
      >
        <span className="mx-4">Tolak/Revisi</span>
      </button>
    </>
  );
};

export default RutenaVerifikasi;
