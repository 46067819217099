import { useAtom } from 'jotai';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../../api/axios';
import TitlePage from '../../../components/TitlePage';
import { UseError } from '../../../hooks/useError';
import { UseSuccess } from '../../../hooks/useSuccess';
import {
  authUserAtom,
  pendatanganSuratAtom,
  penerimaManfaatAtom,
} from '../../../store/jotai';
import Loading from '../../../components/Loading';
import ButtonAjukanKembali from '../../../components/Pengajuan/ButtonAjukanKembali';

const EditPengajuan = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [authUser] = useAtom(authUserAtom);

  const [formData, setFormData] = useState();
  const [date, setDate] = useState(null);
  const [penerima_manfaat] = useAtom(penerimaManfaatAtom);
  const [pendatangan_surat] = useAtom(pendatanganSuratAtom);

  const rutiyaniDetail = useQuery(
    'rutiyaniDetail',
    () => {
      return apiClient.get(`/rutiyani/pengajuan/show/${id}`);
    },
    {
      enabled: !!id,
      retry: true,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        if (data.data.data.length === 0) {
          navigate('/404');
        }

        setFormData(data.data.data);
        setDate(new Date(data.data.data.tanggal_surat));
        // getKelurahan.mutate(data.data.data.kecamatan_id);
      },
      onError: (err) => {
        UseError(err);
        navigate('/rutiyani/pengajuan');
      },
    }
  );

  const updateRutiyani = useMutation(
    'updateRutiyani',
    (e) => {
      e.preventDefault();

      if (formData.tanggal_surat) {
        // format date to yyyy-mm-dd
        const date = new Date(formData.tanggal_surat);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        formData['tanggal_surat'] = formattedDate;
      }

      const form = new FormData();
      form.append('pengajuan_id', formData.id);
      form.append('nomor_surat', formData.nomor_surat);
      form.append('tanggal_surat', formData.tanggal_surat);
      form.append('kecamatan', formData.kecamatan_id);
      form.append('kelurahan', formData.kelurahan_id);
      form.append('pendatangan_surat', formData.pendatangan_surat);
      form.append('penerima_manfaat', formData.penerima_manfaat);
      form.append('lokasi_usulan_rtlh', formData.lokasi_usulan_rtlh);
      form.append('jumlah_unit', formData.jumlah_unit);

      formData.dokumen_permohonan_file &&
        form.append('dokumen_permohonan', formData.dokumen_permohonan_file);
      formData.dokumen_sk_file &&
        form.append('dokumen_sk', formData.dokumen_sk_file);

      return apiClient.post(`/rutiyani/pengajuan/update`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: (data) => {
        UseSuccess('Data berhasil diupdate.');
        queryClient.invalidateQueries('rutiyaniDetail');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <TitlePage
        title={'Edit Pengajuan'}
        subtitle={'Data pengajuan rutiyani yang telah diajukan oleh masyarakat'}
      />

      {rutiyaniDetail.isFetching ? (
        <Loading />
      ) : (
        <form className="mb-4" onSubmit={updateRutiyani.mutate}>
          <div className="d-grid gap-4 mb-4">
            <div className="row">
              <span className="fw-semibold mb-3 h5">Info Surat</span>

              <div className="col-3 mb-3">
                <label htmlFor="nomor_surat" className="form-label">
                  Nomor Surat :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="nomor_surat"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.nomor_surat}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="tanggal_surat" className="form-label">
                  Tanggal Surat :
                </label>
                <ReactDatePicker
                  className="form-control p-2"
                  dateFormat={'yyyy-MM-dd'}
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setFormData({ ...formData, tanggal_surat: date });
                  }}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="pendatangan_surat" className="form-label">
                  Pendatangan Surat Usulan :
                </label>

                <Form.Select
                  aria-label="Pilih Penerima Manfaat"
                  name="pendatangan_surat"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={formData?.pendatangan_surat}
                >
                  <option hidden value={''}>
                    Pilih Pendatangan Surat
                  </option>
                  {pendatangan_surat.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="penerima_manfaat" className="form-label">
                  Penerima Manfaat :
                </label>

                <Form.Select
                  aria-label="Pilih Penerima Manfaat"
                  name="penerima_manfaat"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={formData?.penerima_manfaat}
                >
                  <option hidden value={''}>
                    Pilih Penerima Manfaat
                  </option>
                  {penerima_manfaat.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="row">
              <span className="fw-semibold mb-3 h5">Lokasi Pengusulan</span>

              {/* {authUser?.roles.find((role) =>
                ['admin_provinsi'].includes(role)
              ) && (
                <div className="col-3 mb-3">
                  <label htmlFor="kota" className="form-label">
                    Kabupaten/Kota :
                  </label>
                  <Form.Select
                    aria-label="Pilih Kecamatan"
                    name="kota"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                      queryClient.removeQueries('getKecamatan');
                      setKelurahanList(RESET);
                    }}
                  >
                    <option hidden value={''}>
                      Pilih Kabupaten/Kota
                    </option>
                    {getKota.isFetched &&
                      kotaList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}

              {authUser?.roles.find((role) =>
                ['admin_provinsi', 'admin_kota'].includes(role)
              ) && (
                <>
                  <div className="col-3 mb-3">
                    <label htmlFor="kecamatan" className="form-label">
                      Kecamatan :
                    </label>
                    <Form.Select
                      aria-label="Pilih Kecamatan"
                      name="kecamatan"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        });
                        getKelurahan.mutate(e.target.value);
                      }}
                      value={formData?.kecamatan_id}
                    >
                      <option hidden value={''}>
                        Pilih Kecamatan
                      </option>
                      {getKecamatan.isFetched &&
                        kecamatanList?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>

                  <div className="col-3 mb-3">
                    <label htmlFor="kelurahan" className="form-label">
                      Kelurahan / Desa :
                    </label>
                    <Form.Select
                      aria-label="Pilih Kecamatan"
                      name="kelurahan"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={formData?.kelurahan_id}
                    >
                      <option hidden value={''}>
                        Pilih Kelurahan / Desa
                      </option>
                      {kelurahanList.length > 0 ? (
                        kelurahanList?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>
                          Pilih Kecamatan Terlebih Dahulu
                        </option>
                      )}
                    </Form.Select>
                  </div>
                </>
              )} */}

              <div className="col-3 mb-3">
                <label htmlFor="lokasi_usulan_rtlh" className="form-label">
                  Lokasi Usulan RTLH :
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="lokasi_usulan_rtlh"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.lokasi_usulan_rtlh}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="jumlah_unit" className="form-label">
                  Jumlah Unit :
                </label>
                <input
                  type="number"
                  className="form-control p-2"
                  name="jumlah_unit"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  defaultValue={formData?.jumlah_unit}
                />
              </div>
            </div>

            <div className="row">
              <span className="fw-semibold h5 mb-0">Upload Dokumen</span>
              <p className="fw-light" style={{ fontSize: 13 }}>
                Silahkan upload dokumen pada form ini dengan format file{' '}
                <span className="fw-semibold">PDF</span>
              </p>

              <div className="col-6 mb-3">
                <label htmlFor="dokumen_permohonan" className="form-label">
                  Surat Permohonan dan Proposal{' '}
                  {authUser.role === 'kepala_desa'
                    ? 'Kepala Desa'
                    : 'Bupati / Wali Kota'}{' '}
                  :
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="dokumen_permohonan_file"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                />

                <p className="fw-light p-1 mb-0" style={{ fontSize: 13 }}>
                  Surat Permohonan ditunjukan kepada Gubernur Kalimantan Barat
                  cq. Kepala Dinas Perumahan Rakyat dan Kawasan Permukiman Prov.
                  Kalbar
                </p>
              </div>

              {authUser.role === 'kepala_desa' && (
                <div className="col-6 mb-3">
                  <label htmlFor="dokumen_sk" className="form-label">
                    Dokumen SK :
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="dokumen_sk_file"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                </div>
              )}

              {authUser.role === 'admin_kota' && (
                <div className="col-6 mb-3">
                  <label htmlFor="dokumen_sk_kumuh" className="form-label">
                    Dokumen SK Kumuh:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="dokumen_sk_kumuh"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <button
              type="submit"
              className={`btn btn-primary px-5 py-2 ${
                updateRutiyani.isLoading && 'disabled'
              } `}
            >
              <span className="fs-5">Submit</span>
            </button>
            {updateRutiyani.isLoading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {(formData?.status === 'Ditolak Provinsi' ||
              formData?.status === 'Ditolak Kota/Kabupaten') && (
              <ButtonAjukanKembali id={id} kategori="rutiyani" />
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default EditPengajuan;
