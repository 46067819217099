import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import apiClient from '../../api/axios';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../store/jotai';
import { Form, Modal } from 'react-bootstrap';
import { UseError } from '../../hooks/useError';
import contoh from '../../assets/doc/Form Surat Dukungan Rekomndasi Rutiyani.docx';

const MySwal = withReactContent(Swal);

const RutiyaniVerifikasi = (id) => {
  const queryClient = useQueryClient();
  const [authUser] = useAtom(authUserAtom);

  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    id,
    status_verifikasi: 'Disetujui',
    keterangan: null,
    dokumen_rekomendasi: null,
    dokumen_sk_kumuh: null,
  });

  const verifikasiPengajuan = (isVerified) => {
    const status =
      isVerified === 1 ? ['Disetujui', 'success'] : ['Ditolak', 'danger'];
    MySwal.fire({
      title: 'Verifikasi pengajuan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: status[0],
      confirmButtonColor: isVerified === 1 ? '#3A7BDA' : '#d33',
      input: isVerified === 0 ? 'textarea' : false,
      inputLabel: 'Keterangan',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await apiClient.post('/admin/verifikasi/pengajuan', {
            id,
            status_verifikasi: status[0],
            keterangan:
              isVerified === 0
                ? MySwal.getPopup().querySelector('textarea').value
                : null,
          });
          queryClient.invalidateQueries('rutiyaniDetail');
          return res.data;
        } catch (err) {
          MySwal.showValidationMessage(
            `Request failed: ${err?.response?.data?.message}`
          );
        }
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(`${status[0]}!`, 'Data berhasil diverifikasi.', 'success');
      }
    });
  };

  const verifikasiWithFile = useMutation(
    'verifikasiWithFile',
    (e) => {
      e.preventDefault();
      return apiClient.post('/admin/verifikasi/pengajuan', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('rutiyaniDetail');
        MySwal.fire('Berhasil!', 'Data berhasil diverifikasi.', 'success');
      },
      onError: (err) => {
        UseError(err);
      },
    }
  );

  return (
    <>
      <button
        className="btn btn-success py-2 mx-2"
        onClick={() => {
          authUser.role === 'admin_kota'
            ? setShowModal(true)
            : verifikasiPengajuan(1);
        }}
      >
        <span className="mx-4">Setuju</span>
      </button>
      <button
        className="btn btn-danger py-2 mx-2"
        onClick={() => verifikasiPengajuan(0)}
      >
        <span className="mx-4">Tolak/Revisi</span>
      </button>

      <Modal
        key={id}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Verifikasi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2" onSubmit={verifikasiWithFile.mutate}>
            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">
                  Rekomendasi
                </label>
              </div>
              <div className="col-7">
                <input
                  type="file"
                  accept="application/pdf"
                  name="dokumen_rekomendasi"
                  className="form-control"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                  required
                />
                <span className="fw-light text-muted p-1">
                  Contoh Format Surat Rekomendasi,{' '}
                  <a href={contoh} target={'_blank'} rel="noreferrer">
                    klik disini
                  </a>
                </span>
              </div>
            </div>
            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">SK Kumuh</label>
              </div>
              <div className="col-7">
                <input
                  type="file"
                  accept="application/pdf"
                  name="dokumen_sk_kumuh"
                  className="form-control"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                  required
                />
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-3 d-flex align-items-end flex-column">
                <label className="col-form-label fw-semibold">Keterangan</label>
              </div>
              <div className="col-7">
                <Form.Select
                  name="keterangan"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  required
                >
                  <option value="" hidden>
                    Pilih Keterangan
                  </option>
                  <option>Dalam Kawasan Kumuh &lt; 10 Ha </option>
                  <option>Dalam Kawasan Kumuh 10 - 15 Ha</option>
                  <option>Dalam Kawasan Kumuh Diatas 15 Ha</option>
                </Form.Select>
              </div>
            </div>

            <div className="row g-5 mb-3">
              <div className="col-7 offset-md-3">
                <button type="submit" className="btn btn-primary">
                  Verifikasi Pengajuan
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RutiyaniVerifikasi;
