const CardDetail = (props) => {
  return (
    <div className="card rounded-3">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-3">{props.title}</h5>
        <div className="row">{props.children}</div>
      </div>
    </div>
  );
};

export default CardDetail;
