import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link, useLocation, useMatch } from 'react-router-dom';
import apiClient from '../api/axios';
import logo from '../assets/img/logo-situanperkim.png';
import { authUserAtom, tokenAtom } from '../store/jotai';

const Navbar = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const activeMenu = pathnames.pop().toString();

  const [, setToken] = useAtom(tokenAtom);
  const [authUser, setAuthUser] = useAtom(authUserAtom);

  const { mutate } = useMutation(
    'logout',
    () => {
      return apiClient.post('/auth/logout');
    },
    {
      onSuccess: () => {
        setToken(RESET);
        setAuthUser(RESET);
        window.location.href = '/';
      },
    }
  );

  const logout = () => {
    mutate();
  };

  return (
    <>
      <div className="position-fixed vh-100" style={{ width: 80 }}>
        <div className="navbar d-flex flex-column justify-content-between align-items-center h-100">
          <div className="d-flex flex-column">
            <Link
              to="/dashboard"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Tooltip on right"
            >
              <img src={logo} width={50} alt="logo" />
            </Link>
          </div>
          <div className="d-flex flex-column align-items-center">
            <OverlayTrigger
              placement={'right'}
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
              <Link to="/dashboard" className="text-secondary">
                <span
                  className={`menu border rounded-3 d-flex align-items-center my-2 
                      ${activeMenu === 'dashboard' && 'bg-primary text-white'}
                    `}
                >
                  <iconify-icon
                    class="p-2"
                    icon={'icomoon-free:home3'}
                    width={30}
                  ></iconify-icon>
                </span>
              </Link>
            </OverlayTrigger>

            {authUser?.role === 'admin_provinsi' && (
              <OverlayTrigger
                placement={'right'}
                overlay={<Tooltip>Data Pengguna</Tooltip>}
              >
                <Link to="/users" className="text-secondary">
                  <span
                    className={`menu border rounded-3 d-flex align-items-center my-2 
                      ${activeMenu === 'users' && 'bg-primary text-white'}
                    `}
                  >
                    <iconify-icon
                      class="p-2"
                      icon={'mdi:users'}
                      width={30}
                    ></iconify-icon>
                  </span>
                </Link>
              </OverlayTrigger>
            )}

            {useMatch('/rutiyani/*') && (
              <>
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>Rutiyani</Tooltip>}
                >
                  <Link to="/rutiyani" className="text-secondary">
                    <span
                      className={`menu border rounded-3 d-flex align-items-center my-2 
                      ${activeMenu === 'rutiyani' && 'bg-primary text-white'}
                    `}
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'fluent:apps-16-filled'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>Daftar Pengajuan</Tooltip>}
                >
                  <Link to="/rutiyani/pengajuan" className="text-secondary">
                    <span
                      className={
                        'menu border rounded-3 d-flex align-items-center my-2 ' +
                        (activeMenu === 'pengajuan' && 'bg-primary text-white')
                      }
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'ep:document'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>
              </>
            )}

            {useMatch('/rutena/*') && (
              <>
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>Rutena</Tooltip>}
                >
                  <Link to="/rutena" className="text-secondary">
                    <span
                      className={`menu border rounded-3 d-flex align-items-center my-2 
                      ${activeMenu === 'rutena' && 'bg-primary text-white'}
                    `}
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'fluent:apps-16-filled'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>Daftar Pengajuan</Tooltip>}
                >
                  <Link to="/rutena/pengajuan" className="text-secondary">
                    <span
                      className={
                        'menu border rounded-3 d-flex align-items-center my-2 ' +
                        (activeMenu === 'pengajuan' && 'bg-primary text-white')
                      }
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'ep:document'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>
              </>
            )}

            {useMatch('/psu/*') && (
              <>
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>PSU</Tooltip>}
                >
                  <Link to="/psu" className="text-secondary">
                    <span
                      className={`menu border rounded-3 d-flex align-items-center my-2 
                      ${activeMenu === 'psu' && 'bg-primary text-white'}
                    `}
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'fluent:apps-16-filled'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip>Daftar Pengajuan</Tooltip>}
                >
                  <Link to="/psu/pengajuan" className="text-secondary">
                    <span
                      className={
                        'menu border rounded-3 d-flex align-items-center my-2 ' +
                        (activeMenu === 'pengajuan' && 'bg-primary text-white')
                      }
                    >
                      <iconify-icon
                        class="p-2"
                        icon={'ep:document'}
                        width={30}
                      ></iconify-icon>
                    </span>
                  </Link>
                </OverlayTrigger>
              </>
            )}
          </div>

          <OverlayTrigger
            placement={'right'}
            overlay={<Tooltip>Logout</Tooltip>}
          >
            <div id="logout" className="mb-2 ">
              <div className="" onClick={logout}>
                <iconify-icon
                  class="p-1 d-flex align-items-center justify-content-center"
                  icon="icons8:shutdown"
                  width={24}
                  style={{
                    border: '1px solid #3A7BDA',
                    borderRadius: 50,
                    width: 36,
                    height: 36,
                    color: '#3A7BDA',
                  }}
                ></iconify-icon>
              </div>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default Navbar;
