import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  let location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  // console.log(pathnames);
  // // last item of pathnames is the current page
  // // so we remove it from the array
  // pathnames.reverse().pop();
  // console.log(pathnames.toString());

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link
              className="text-white fw-lighter text-decoration-none text-capitalize"
              to={'/dashboard'}
            >
              {'Dashboard'}
            </Link>
          </li>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;

            return name !== 'dashboard' && isLast ? (
              <li
                className="breadcrumb-item text-white fw-semibold text-capitalize"
                aria-current="page"
                key={index}
              >
                {name}
              </li>
            ) : (
              name !== 'dashboard' && (
                <li className="breadcrumb-item" key={index}>
                  <Link
                    className="text-white fw-lighter text-decoration-none text-capitalize"
                    to={routeTo}
                  >
                    {name}
                  </Link>
                </li>
              )
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
